import React ,{useState,useEffect} from 'react'
import './user.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang,department} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const UserCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [users, setUsers] = useState('');
    const [keyss,setKeys] = useState([])
    const [valuess,setvalues] = useState([])
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // useEffect(()=>{
    //     fetchAllParents();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/department/parent').then(res=>{
    //         setParents(res.data.data);
    //     })
    // }

    const onSubmit = (data, e) => {
        // data.image_url = data.image_url[0];
        // delete departments.image_url;        
        data = {...data, ...users};
        data.user_id = cookies.get('userAuth').id
        setLoader(true)
        http.post('/admin/register-user',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/user");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleChange = (e) =>{
        console.log(e);
        let user = {...users};
        user[e.target.name] = e.target.value
        setUsers(user);
    }
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create User</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.name? 
                    <span className="text-danger">
                      {getErrorObj.name}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        // {...register("email")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.email? 
                    <span className="text-danger">
                      {getErrorObj.email}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Phone</Form.Label>
                    <Form.Control
                        type="text"
                        name="phone"
                        placeholder="Enter phone"
                        // {...register("phone")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.phone? 
                    <span className="text-danger">
                      {getErrorObj.phone}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/user">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default UserCreate