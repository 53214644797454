import React,{useState,useEffect } from 'react';
import './jobpost.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';

const JobPost = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [jobPost, setJobPost] = useState([]);
  const [getDeleteJobPost, setDeleteJobPost] = useState(false);
  const [search, setSearch] = useState("");
  const [filterJobPost, setFilterJobPost] = useState([]);

  const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  
  const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    const deleteEvent = (id) => 
    {
        http.get('/job/delete/'+id,{
          headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           } 
        }).then((res)=>{
            setDeleteJobPost(true);
            setTimeout(()=>{
                setDeleteJobPost(false)}
            ,2000) 
                
        })
        handleClose();
    };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllJobPost(getCurrentPage);
    },[getDeleteJobPost]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/job/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterJobPost(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);

    console.log(filterJobPost)

    // +++++++++++++++++++
    const fetchAllJobPost = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/job/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data)
        setFilterJobPost(response.data.data.data)
		setJobPost(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/job/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data);
        
		setJobPost(response.data.data.data);
        console.log("+++",response.data.data.data);
        setFilterJobPost(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllJobPost(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++
    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }

    console.log(jobPost)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable:true
        },
        {
            name: "Sub Title",
            selector: row => (row.sub_title)? row.sub_title : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Department",
            selector: row => (row.department_id) ? row.department.title:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Vacancy",
            selector: row => (row.vacancy)?row.vacancy:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Employment",
            selector: row => (row.employment)?row.employment:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Deadline",
            selector: row => (row.deadline) ? formateDate(row.deadline) 
            : 
            <Badge bg="warning" text="dark">N/A</Badge>,
        },
        {
            name: "Image",
            selector: row => (row.image_url) ? <img width={50} height={50} src={image+'/'+row.image_url} /> 
            : 
            <Badge bg="warning" text="dark">N/A</Badge>,
        },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                    <ComponentGuard needsPermission={['job.details']}>
                        <Link to={"read/"+row.id}>
                            <Button className="action__btn" variant="info">
                                <GrView/>
                            </Button>
                        </Link>
                    </ComponentGuard>

                    <ComponentGuard needsPermission={['job.update']}>
                        <Link to={"edit/"+row.id}>
                            <Button className="action__btn" variant="primary">
                                <AiTwotoneEdit/>
                            </Button>
                        </Link>
                    </ComponentGuard>

                    <ComponentGuard needsPermission={['job.delete']}>
                        <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                            <MdDelete/>
                        </Button>
                    </ComponentGuard>
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>

            {     
                (getDeleteJobPost) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0',zIndex:'111111'}}>
                            <Toast.Body>Job Post Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            <ComponentGuard needsPermission={['job.create']}>
                <Link to="create">
                    <Button className="create_btn btn btn-primary" variant="primary">Add Job Post</Button>
                </Link>
            </ComponentGuard>

            <DataTable 
                title="Job Post List"
                columns={columns} 
                data={filterJobPost} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input 
                    type="text" 
                    placeholder="Search Here" 
                    className="search_btn form-control"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}
                    />
                }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/job-post">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteEvent(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </Layout>
        </>
    );
};

export default JobPost;