import React,{useState,useEffect} from 'react'
import './news.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';
const NewsRead = () => {
    const [newses, setNewses] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL
    const cookies = new Cookies()
    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(newses)
    useEffect(()=>{
        fetchSingleNewses();
    },[]);

    const fetchSingleNewses = () => {
        http.get('news/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setNewses(res.data.data);
        })
    }

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {newses == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(newses.title)?newses.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Sub Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(newses.sub_title)?newses.sub_title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Department:</label>
                    </div>
                    <div className="col-sm-6">
                        {(newses.department_id)?newses.department.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(newses.lang_parent)?newses.lang_parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(newses.image_url)?
                             <p>
                             {
                               (newses.image_url) ? <a href={image+'/'+newses.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(newses.lang)?newses.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Description:</label>
                    </div>
                     {newses.description?
                     
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={newses.description}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }

                </div>
            }
            </div>
        </div>

        <ComponentGuard needsPermission={['news.update']}>
            <Link to={"/news/edit/"+newses.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link> 
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default NewsRead