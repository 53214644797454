import React ,{useState,useEffect} from 'react'
// import nav from './nav.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast,Tabs,Tab   } from 'react-bootstrap';
import http from "../../http"
import {day} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DateRangePicker,CheckPicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import DatePicker from '@rsuite/multi-date-picker';
// import '@rsuite/multi-date-picker/lib/styles.less'
import moment from 'moment/moment';
const HolidayCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [holidays,setHolidays] = useState([]);
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [departments, setdepartments] = useState([]);
    const [getDept, setDept] = useState();
    const [keys,setKeys] = useState([]);
    const [values,setValues] = useState([]);
    const [date,setDate] = useState([]);
    const [multiDate,setMultiDate] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;
    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // useEffect(()=> {
    //     frommateDate(holidays)
    // },[holidays]);


        // obj to array convert
    let keyarr = []
    let valarr = []
    useEffect(()=> {
        Object.entries(day).forEach(([key,value])=>{
            keyarr.push(key)
            valarr.push(value)
        })

        setKeys(keyarr);
        setValues(valarr);
    },[day]);

    // console.log(values)
    // console.log(holidays.otherDate)

    
    const onSubmit = (data, e) => {
        data = {...data, ...holidays,date,multiDate};
        console.log(data);
        data.user_id = cookies.get('userAuth').id
        // data.date = (data.date) ? data.date : data.multiDate 
        console.log(data)
        setLoader(true)
        http.post('/holiday/create',data, {
            headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                "Content-type": "Application/json"
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/holiday");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            console.log(err)
            // console.log(Object.keys(err.response.data.data))
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        console.log(e);
        let holiday = {...holidays};
        holiday[e.target.name] = e.target.value
        setHolidays(holiday);
    }
    
    const formateDate = (val) =>{
        let day = holidays.holiday;   
        let tempDateVal0 = new Date(val[0]).toUTCString().toString();
        let tempDateVal1 = new Date(val[1]).toUTCString().toString();
        // console.log(tempDate)
        let fetchDateVal0 = tempDateVal0.substring(5, 7);
        let fetchDateVal1 = tempDateVal1.substring(5, 7);

        let monthConvertVal0 = 
        tempDateVal0.substring(8, 11)=='Jan' ? "01" :
        tempDateVal0.substring(8, 11)=='Feb' ? "02" :
        tempDateVal0.substring(8, 11)=='Mar' ? "03" :
        tempDateVal0.substring(8, 11)=='Apr' ? "04" :
        tempDateVal0.substring(8, 11)=='May' ? "05" :
        tempDateVal0.substring(8, 11)=='Jun' ? "06" :
        tempDateVal0.substring(8, 11)=='Jul' ? "07" :
        tempDateVal0.substring(8, 11)=='Aug' ? "08" :
        tempDateVal0.substring(8, 11)=='Sep' ? "09" :
        tempDateVal0.substring(8, 11)=='Oct' ? "10" :
        tempDateVal0.substring(8, 11)=='Nov' ? "11" : 
        tempDateVal0.substring(8, 11)=='Dec' ? "12" : null
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
        let dateVal0 = tempDateVal0.substring(12, 16) + "-" + monthConvertVal0 + "-" + fetchDateVal0    
        
        let monthConvertVal1 = 
        tempDateVal1.substring(8, 11)=='Jan' ? "01" :
        tempDateVal1.substring(8, 11)=='Feb' ? "02" :
        tempDateVal1.substring(8, 11)=='Mar' ? "03" :
        tempDateVal1.substring(8, 11)=='Apr' ? "04" :
        tempDateVal1.substring(8, 11)=='May' ? "05" :
        tempDateVal1.substring(8, 11)=='Jun' ? "06" :
        tempDateVal1.substring(8, 11)=='Jul' ? "07" :
        tempDateVal1.substring(8, 11)=='Aug' ? "08" :
        tempDateVal1.substring(8, 11)=='Sep' ? "09" :
        tempDateVal1.substring(8, 11)=='Oct' ? "10" :
        tempDateVal1.substring(8, 11)=='Nov' ? "11" : 
        tempDateVal1.substring(8, 11)=='Dec' ? "12" : null
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
        let dateVal1 = tempDateVal1.substring(12, 16) + "-" + monthConvertVal1 + "-" + fetchDateVal1    
        console.log(day)
        console.log(dateVal0)
        console.log(dateVal1)
        
        return [dateVal0,dateVal1]
    }

    useEffect(()=>{
        finalDate();
    },[
        holidays.holiday,
        holidays.from,
    ]);

    const finalDate = () =>{
        console.log(holidays.from,holidays.holiday);

        if(holidays.from && holidays.holiday){
            var start = moment(holidays.from[0]), // Sept. 1st
                end   = moment(holidays.from[1]), // Nov. 2nd
                day   = holidays.holiday;              // Sunday
    
            var result = [];
            var current = start.clone();
    
            for(let i = 0; i<day.length;i++){
                while (current.day(7 + day[i]).isBefore(end)) {
                result.push(current.clone());
                }
                current = start.clone();
            }
            let empArr = []
    
            result.map((m)=>
            {
                let x = m.format('LLLL')
                empArr.push( dateConvert(x))
            }
             )
             setDate(empArr)
        }
        else{
            return [];
        }
    }

    console.log(date)
    // console.log(holidays)


    function dateConvert(val){
       
        let tempDate = new Date(val).toUTCString().toString();
        let fetchDate = tempDate.substring(5, 7);

        let monthConvert = 
        tempDate.substring(8, 11)=='Jan' ? "01" :
        tempDate.substring(8, 11)=='Feb' ? "02" :
        tempDate.substring(8, 11)=='Mar' ? "03" :
        tempDate.substring(8, 11)=='Apr' ? "04" :
        tempDate.substring(8, 11)=='May' ? "05" :
        tempDate.substring(8, 11)=='Jun' ? "06" :
        tempDate.substring(8, 11)=='Jul' ? "07" :
        tempDate.substring(8, 11)=='Aug' ? "08" :
        tempDate.substring(8, 11)=='Sep' ? "09" :
        tempDate.substring(8, 11)=='Oct' ? "10" :
        tempDate.substring(8, 11)=='Nov' ? "11" : 
        tempDate.substring(8, 11)=='Dec' ? "12" : null
        let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
        console.log(date)
        return date;
    }

    const singleFrommateDate = (val) =>{
        console.log(val)
        let multiDateArr = []
        for(let i = 0; i<val.length;i++){
            let tempDate = new Date(val[i]).toUTCString().toString();
            // console.log(tempDate)
            let fetchDate = tempDate.substring(5, 7);
    
            let monthConvert = 
            tempDate.substring(8, 11)=='Jan' ? "01" :
            tempDate.substring(8, 11)=='Feb' ? "02" :
            tempDate.substring(8, 11)=='Mar' ? "03" :
            tempDate.substring(8, 11)=='Apr' ? "04" :
            tempDate.substring(8, 11)=='May' ? "05" :
            tempDate.substring(8, 11)=='Jun' ? "06" :
            tempDate.substring(8, 11)=='Jul' ? "07" :
            tempDate.substring(8, 11)=='Aug' ? "08" :
            tempDate.substring(8, 11)=='Sep' ? "09" :
            tempDate.substring(8, 11)=='Oct' ? "10" :
            tempDate.substring(8, 11)=='Nov' ? "11" : 
            tempDate.substring(8, 11)=='Dec' ? "12" : null
            // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
            let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
            multiDateArr.push(date)
        }
        console.table(multiDateArr)
        setMultiDate(multiDateArr)
        setDate(multiDateArr)

    }
    console.log(multiDate)
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }
        <h2 style={{color: "black",textDecoration: "underline"}}>Create Holiday</h2>
        <Tabs
            defaultActiveKey="weekend"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="weekend" title="Weekend">
                <div className="form_design">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className="form_label">Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            placeholder="Enter title"
                            // {...register("title")}
                            onChange={(e) => handleChange(e)}

                        />
                        {getErrorObj.title? 
                        <span className="text-danger">
                        {getErrorObj.title}
                        </span> : null
                        }
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="form_label">Holiday</Form.Label>
                        <CheckPicker  name="holiday" 
                            data={keys?.map((item,ele) => ({ label: day[item], value: ele }))} 
                            style={{ width: "100%" }} 
                            //   {...register("dr_speciality_id")} 
                            onChange={(value) => setHolidays({...holidays, "holiday": value})} 
                        />

                        {getErrorObj.days? 
                        <span className="text-danger">
                        {getErrorObj.days}
                        </span> : null
                        }
                    </Form.Group>
                    
                
                    <Form.Group>
                        <Form.Label className="form_label">Date Range</Form.Label>
                        <DateRangePicker placeholder="Select Date Range" 
                            style={{ width: "100%" }}
                            disabledDate={beforeToday()}
                            onChange={(value) => setHolidays({...holidays, "from": formateDate(value)})}
                        />
                        {getErrorObj.date? 
                        <span className="text-danger">
                        {getErrorObj.date}
                        </span> : null
                        }
                    </Form.Group>
                    <div className="mt-4">
                        <Button className="action__btn" variant="primary" type="submit">
                            Save
                        </Button>
                        <Link to="/holiday">
                            <Button className="action__btn" variant="info">
                                Back to Home
                            </Button>
                        </Link>
                    </div>
                </Form>
                </div>
            </Tab>
            <Tab eventKey="other" title="Other">
            <div className="form_design">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className="form_label">Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            placeholder="Enter other title"
                            // {...register("title")}
                            onChange={(e) => handleChange(e)}

                        />
                        {getErrorObj.title? 
                        <span className="text-danger">
                        {getErrorObj.title}
                        </span> : null
                        }
                    </Form.Group>


                    <Form.Group>
                        <Form.Label className="form_label">Other Holiday Date</Form.Label>
                        <DatePicker
                            format="yyyy-MM-dd"
                            showMeridian
                            oneTap
                            showWeekNumbers
                            style={{ width: "100%" }}
                            disabledDate={beforeToday()}
                            onChange={(value) => setHolidays({...holidays, "date": singleFrommateDate(value)})}
                            // onChange={(value) => frommateDate(value)}

                        />
                        {getErrorObj.date? 
                        <span className="text-danger">
                        {getErrorObj.date}
                        </span> : null
                        }
                    </Form.Group>

                    <div className="mt-4">
                        <Button className="action__btn" variant="primary" type="submit">
                            Save
                        </Button>
                        <Link to="/holiday">
                            <Button className="action__btn" variant="info">
                                Back to Home
                            </Button>
                        </Link>
                    </div>
                </Form>
                </div>
            </Tab>
        </Tabs>
  
    </Layout>
    </>
  )
}

export default HolidayCreate