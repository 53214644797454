import React, { useState, createContext } from "react";

export const NavContext = createContext();

export const NavProvider = (props) => {
  
    const [navs, setNavs] = useState([
    { id: 1, name: "Salman", position: "Front End Dev", salary: 28000 },
    { id: 2, name: "Shuvo", position: "Data Scientist", salary: 26000 },
    { id: 3, name: "Mahadi", position: "Full Stack Dev", salary: 27000 },
  ]);

  return (
    <NavContext.Provider value={[navs, setNavs]}>
      {props.children}
    </NavContext.Provider>
  );
};