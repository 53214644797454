import React,{useState,useEffect} from 'react'
import './publication.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import { TagInput } from 'rsuite';
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';
const PublicationRead = () => {
    const [publications, setPublications] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL
    const cookies = new Cookies()
    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(publications)
    useEffect(()=>{
        fetchSinglePublications();
    },[]);

    const fetchSinglePublications = () => {
        http.get('publication/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setPublications(res.data.data);
        })
    }

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {publications == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.title)?publications.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Sub Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.sub_title)?publications.sub_title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Thumbnail:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.image_url)?
                             <p>
                             {
                               (publications.image_url) ? <a href={image+'/'+publications.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Attachment:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.file_url)?
                             <p>
                             {
                               (publications.file_url) ? <a href={image+'/'+publications.file_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Type:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.type)?publications.type : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                   
                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.lang_parent)?publications.lang_parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.lang)?publications.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Meta Tag:</label>
                    </div>
                    <div className="col-sm-6">
                        {(publications.meta)
                            ?
                            <TagInput
                                plaintext
                                defaultValue={Object.values(publications.meta)}
                                style={{ width: 300 }}
                                menuStyle={{ width: 300 }}
                            /> 
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['publication.update']}>
            <Link to={"/publication/edit/"+publications.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default PublicationRead