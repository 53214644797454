import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './album.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const AlbumEdit = () => {
    const {id} = useParams()
    const [albums, setAlbums] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // useEffect(()=>{
    //   fetchAllParents();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/news/parent').then(res=>{
    //         setParents(res.data.data);
    //     })
    // }

//     useEffect(()=>{
//       if(newses.department_id)
//          getDeptSearch()
//     },[newses]);
  
  
//   const getDeptSearch = (val = "") =>{
//       let val1 = val.length > 0 ? val : '*';
//       if(val.length > 0) {
//         http.get(`/department/search/${val}`, {
//           headers:{
//            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
//           }  
//       }).then((response)=>{
//           setDepartments(response.data.data);
//         })
//       }else if(newses.department_id){
//         http.get(`/department/get/${newses.department_id}`, {
//           headers:{
//            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
//           }  
//       }).then((response)=>{
//           setDepartments({'data': [response.data.data]});
//         })
//       } else {
//         http.get(`/department/search/${val1}`, {
//           headers:{
//            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
//           }  
//       }).then((response)=>{
//           setDepartments({'data': []});
//         })
//       }
//   }
    // console.log(parents)

    useEffect(()=>{
      fetchEditAlbums();
    },[]);

    const fetchEditAlbums = () => {
        http.get('album/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setAlbums(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
        .filter(([_, v]) => v != null && v != "")
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
    //   const image_url = data.image_url[0];
    //   delete images.image_url;
      // delete images.department
      data = {...data, ...albums};
      // data = removeEmpty(data)
    //   data.image_url = image_url;
    //   data.album_id = 1;
      
      data.user_id = cookies.get('userAuth').id
      console.log(data);
      setLoader(true)

      http.post('/album/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        setSuccess(true);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/album");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let album = {...albums};
    album[e.target.name] = e.target.value
    setAlbums(album);
}
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {albums == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Album</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={albums.title}
                      placeholder="Enter title"
                      // {...register("title")}
                      onChange={(e) => handleChange(e)}

                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/album">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default AlbumEdit