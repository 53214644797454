import React,{useState,useEffect } from 'react';
import './image.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';

const Image = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [images, setImages] = useState([]);
  const [getDeleteImage, setDeleteImage] = useState(false);
  const [search, setSearch] = useState("");
  const [filterImages, setFilterImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [imageShow, setImageShow] = useState("");
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  
  const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    const handleClose = () => {
        setShow(false);
        setImageShow('');
    };
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    const deleteImage = (id) => 
    {
        http.get('/album/image/delete/'+id,{
          headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           } 
        }).then((res)=>{
            setDeleteImage(true);
            setTimeout(()=>{
                setDeleteImage(false)}
            ,2000) 
                
        })
        handleClose();
    };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllImages(getCurrentPage);
    },[getDeleteImage]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/album/image/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterImages(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);

    console.log(filterImages)

    // +++++++++++++++++++
    const fetchAllImages = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/album/image/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data)
        setFilterImages(response.data.data.data)
		setImages(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/album/image/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data);
        
		setImages(response.data.data.data);
        console.log("+++",response.data.data.data);
        setFilterImages(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllImages(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++


    function handleImageShow(img){
        setImageShow(img)
    }

    console.log(images)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Title",
            selector: row => (row.album_id) ? row.album.title:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Image",
            selector: row => (row.image_url) ? <img onClick={()=>handleImageShow(image+'/'+row.image_url)} width={50} height={50} src={image+'/'+row.image_url} /> 
            : 
            <Badge bg="warning" text="dark">N/A</Badge>,
        },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                    {/* <ComponentGuard needsPermission={['news.details']}>
                        <Link to={"read/"+row.id}>
                            <Button className="action__btn" variant="info">
                                <GrView/>
                            </Button>
                        </Link>
                    </ComponentGuard> */}

                    <ComponentGuard needsPermission={['image.update']}>
                        <Link to={"edit/"+row.id}>
                            <Button className="action__btn" variant="primary">
                                <AiTwotoneEdit/>
                            </Button>
                        </Link>
                    </ComponentGuard>

                    <ComponentGuard needsPermission={['image.delete']}>
                        <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                            <MdDelete/>
                        </Button>
                    </ComponentGuard>
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>

            {     
                (getDeleteImage) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0',zIndex:'111111'}}>
                            <Toast.Body >Image Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            <ComponentGuard needsPermission={['image.create']}>
                <Link to="create" style={{paddingRight:"5px"}}>
                    <Button className="create_btn btn btn-primary" variant="primary">Add Image</Button>
                </Link>
            </ComponentGuard>

            {/* <ComponentGuard needsPermission={['album.getAll']}>
                <Link to="album">
                    <Button className="create_btn btn btn-primary" variant="primary">Album List</Button>
                </Link>
            </ComponentGuard> */}

            <DataTable 
                title="Image List"
                columns={columns} 
                data={filterImages} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input 
                    type="text" 
                    placeholder="Search Here" 
                    className="search_btn form-control"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}
                    />
                }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/image">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteImage(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            <Modal show={imageShow} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img src={imageShow} width="100%" height="400px" style={{objectFit:"cover"}}/>
                </Modal.Body>
            </Modal>
        </Layout>
        </>
    );
};

export default Image;