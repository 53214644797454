import axios from "axios"
import http from "../../http"
import Cookies from 'universal-cookie';

import { FETCH_UPDATED_USER_LIST_REQ, FETCH_UPDATED_USER_LIST_SUCC, FETCH_UPDATED_USER_LIST_ERR } from "./listType"


export const fetch_updated_user_list_req = ()=>{
   return {
        type: FETCH_UPDATED_USER_LIST_REQ
   }
}

export const fetch_updated_user_list_succ = (data)=>{

    return {
         type: FETCH_UPDATED_USER_LIST_SUCC,
         payload: data
    }
 }

 export const fetch_updated_user_list_err = (err)=>{
    return {
         type: FETCH_UPDATED_USER_LIST_ERR,
         payload: err
    }
 }

 export const fetchUpdatedUsers =(data)=>{
    const cookies = new Cookies();

     return (dispatch)=>{
         dispatch(fetch_updated_user_list_req);
         const url = `${process.env.BASE_URL}/api/auth/update/${data.userId}`
        //  console.log(JSON.parse(localStorage.getItem("userData"))?.token)

         setTimeout(()=>{
            http.post(`/auth/update/${data.userId}`,data.userData,{
                headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                "Content-type":"multipart/form-data",    
                }  
            })
             .then(data => {
                const list_data = data.data;
                // console.log(list_data)
                 dispatch(fetch_updated_user_list_succ(list_data))
             })
             .catch( err =>{
                 const error  = err.message;
                 dispatch(fetch_updated_user_list_err(error))
     
             })
         },1000)
     }
 
  }