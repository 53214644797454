import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard.jsx';
import ForgotPassword from './pages/Auth/ForgotPassword.jsx';
import ProductList from './pages/Forbidden.jsx';
import { NavProvider } from "./components/NavContext/NavContext";
import Login from './pages/Auth/Login';
import Cookies from 'universal-cookie';
import loginCheck from './helpers/loginCheck';


import File from './pages/FileSystem';
// import FileRead from './pages/FileSystem/FileRead';
import FileCreate from './pages/FileSystem/FileCreate';
import FileEdit from './pages/FileSystem/FileEdit';

import Menu from './pages/Menu';
import MenuRead from './pages/Menu/MenuRead.jsx';
import MenuCreate from './pages/Menu/MenuCreate';
import MenuEdit from './pages/Menu/MenuEdit';

import Appointment from './pages/Appointment';
import AppointmentCreate from './pages/Appointment/AppointmentCreate';
import AppointmentRead from './pages/Appointment/AppointmentRead';
import AppointmentEdit from './pages/Appointment/AppointmentEdit';


import News from './pages/News';
import NewsCreate from './pages/News/NewsCreate';
import NewsRead from './pages/News/NewsRead';
import NewsEdit from './pages/News/NewsEdit';

import Team from './pages/Team';
import TeamCreate from './pages/Team/TeamCreate';
import TeamEdit from './pages/Team/TeamEdit';

import Department from './pages/Department';
import DepartmentCreate from './pages/Department/DepartmentCreate';
import DepartmentRead from './pages/Department/DepartmentRead';
import DepartmentEdit from './pages/Department/DepartmentEdit';

import Event from './pages/Event';
import EventCreate from './pages/Event/EventCreate';
import EventRead from './pages/Event/EventRead';
import EventEdit from './pages/Event/EventEdit';

import SpecialDoctor from './pages/SpecialDoctor';
import SpecialDoctorCreate from './pages/SpecialDoctor/SpecialDoctorCreate';
import SpecialDoctorRead from './pages/SpecialDoctor/SpecialDoctorRead';
import SpecialDoctorEdit from './pages/SpecialDoctor/SpecialDoctorEdit';

import Doctor from './pages/Doctor';
import DoctorCreate from './pages/Doctor/DoctorCreate';
import DoctorRead from './pages/Doctor/DoctorRead';
import DoctorEdit from './pages/Doctor/DoctorEdit';

import Service from './pages/Service';
import ServiceCreate from './pages/Service/ServiceCreate';
import ServiceRead from './pages/Service/ServiceRead';
import ServiceEdit from './pages/Service/ServiceEdit';

import Package from './pages/Package';
import PackageCreate from './pages/Package/PackageCreate';
import PackageRead from './pages/Package/PackageRead';
import PackageEdit from './pages/Package/PackageEdit';

import Page from './pages/Page';
import PageCreate from './pages/Page/PageCreate';
import PageRead from './pages/Page/PageRead';
import PageEdit from './pages/Page/PageEdit';

import Slider from './pages/Slider';
import SliderCreate from './pages/Slider/SliderCreate';
import SliderRead from './pages/Slider/SliderRead';
import SliderEdit from './pages/Slider/SliderEdit';

import User from './pages/User';
import UserCreate from './pages/User/UserCreate';

import Holiday from './pages/Holiday';
import HolidayCreate from './pages/Holiday/HolidayCreate';
import HolidayEdit from './pages/Holiday/HolidayEdit';
import Calender from './pages/Holiday/Calender';

import DoctorVisit from './pages/DoctorVisit';
import DoctorVisitCreate from './pages/DoctorVisit/DoctorVisitCreate';
import DoctorVisitEdit from './pages/DoctorVisit/DoctorVisitEdit';
import DoctorVisitCalender from './pages/DoctorVisit/DoctorVisitCalender';

import Treatment from './pages/Treatment'
import TreatmentCreate from './pages/Treatment/TreatmentCreate'
import TreatmentRead from './pages/Treatment/TreatmentRead'
import TreatmentEdit from './pages/Treatment/TreatmentEdit'

import Room from './pages/Room'
import RoomCreate from './pages/Room/RoomCreate'
import RoomRead from './pages/Room/RoomRead'
import RoomEdit from './pages/Room/RoomEdit'

import Booking from './pages/Booking'
import BookingCreate from './pages/Booking/BookingCreate'
import BookingRead from './pages/Booking/BookingRead'
import BookingEdit from './pages/Booking/BookingEdit'

import Role from './pages/Role';
import RoleCreate from './pages/Role/RoleCreate';
import RolePermission from './pages/Role/RolePermission';
import RoleRevokePermission from './pages/Role/RoleRevokePermission';
import RoleAssignToUser from './pages/Role/RoleAssignToUser';
import RoleUserList from './pages/Role/RoleUserList';
import DeleteRole from './pages/Role/DeleteRole';

import Publication from './pages/Publication';
import PublicationCreate from './pages/Publication/PublicationCreate';
import PublicationRead from './pages/Publication/PublicationRead';
import PublicationEdit from './pages/Publication/PublicationEdit';


import JobPost from './pages/JobPost';
import JobPostCreate from './pages/JobPost/JobPostCreate';
import JobPostRead from './pages/JobPost/JobPostRead';
import JobPostEdit from './pages/JobPost/JobPostEdit';

import InnerPage from './pages/InnerPage';
import InnerPageCreate from './pages/InnerPage/InnerPageCreate';
import InnerPageRead from './pages/InnerPage/InnerPageRead';
import InnerPageEdit from './pages/InnerPage/InnerPageEdit';


import Image from './pages/Image';
import ImageCreate from './pages/Image/ImageCreate';
import ImageEdit from './pages/Image/ImageEdit';
import Album from './pages/Image/Album';
import AlbumCreate from './pages/Image/AlbumCreate';
import AlbumEdit from './pages/Image/AlbumEdit';


import ActivityLog from './pages/ActivityLog'

import ProtectedRoute from './helpers/ProtectedRoute';
import Icon from './components/Icon/icon';
import Forbidden from './pages/Forbidden.jsx';
import NotFound from './pages/NotFound';

import UserProfile from './pages/UserProfile';
import ResetPassword from './pages/Auth/ResetPassword.jsx';
import FormBuilder from './pages/FormBuilder';
import DeleteMultiple from './pages/DoctorVisit/DeleteMultiple';
import YoutubeLink from './pages/YoutubeLink/index.jsx';
import YoutubeLinkCreate from './pages/YoutubeLink/YoutubeLinkCreate.jsx';
import YoutubeLinkEdit from './pages/YoutubeLink/YoutubeLinkEdit.jsx';

import PriceCreate from './pages/Price/PriceCreate.jsx';
import PriceRead from './pages/Price/PriceRead.jsx';
import PriceEdit from './pages/Price/PriceEdit.jsx';
import Price from './pages/Price';




const App = () => {
  const cookies = new Cookies();
  // const [loggedIn, setLoggedIn] = React.useState(false);
  
  // const loginStatus = cookies.get('userAuth')?.token != null;
  
  // if(loggedIn !== loginStatus) {setLoggedIn(loginStatus)};
  
  // console.log(cookies.get('userAuth')); 

  return (
    
    <NavProvider>
      <BrowserRouter>
        <div>
          <Routes>
              <Route path="/login" element= {<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/forbidden" element={<Forbidden />} />

              <Route path="/user/profile" element={<UserProfile />} />
              <Route path="/form-builder" element={<FormBuilder />} />

              <Route path="/file/" element={<ProtectedRoute needsPermission={['file.getAll']}><File /></ProtectedRoute>} />
              <Route path="/file/create" element={<ProtectedRoute needsPermission={['file.create']}><FileCreate /></ProtectedRoute>} />
              {/* <Route path="/file/read/:id" element={<ProtectedRoute needsPermission={['file.details']}><MenuRead /></ProtectedRoute>} /> */}
              <Route path="/file/edit/:id" element={<ProtectedRoute needsPermission={['file.update']}><FileEdit /></ProtectedRoute>} />

              <Route path="/youtube-link/" element={<ProtectedRoute needsPermission={['youtube-link.getAll']}><YoutubeLink /></ProtectedRoute>} />
              <Route path="/youtube-link/create" element={<ProtectedRoute needsPermission={['youtube-link.create']}><YoutubeLinkCreate /></ProtectedRoute>} />
              {/* <Route path="/file/read/:id" element={<ProtectedRoute needsPermission={['file.details']}><MenuRead /></ProtectedRoute>} /> */}
              <Route path="/youtube-link/edit/:id" element={<ProtectedRoute needsPermission={['youtube-link.update']}><YoutubeLinkEdit /></ProtectedRoute>} />

              <Route path="/menu/" element={<ProtectedRoute needsPermission={['menu.getAll']}><Menu /></ProtectedRoute>} />
              <Route path="/menu/create" element={<ProtectedRoute needsPermission={['menu.create']}><MenuCreate /></ProtectedRoute>} />
              <Route path="/menu/read/:id" element={<ProtectedRoute needsPermission={['menu.details']}><MenuRead /></ProtectedRoute>} />
              <Route path="/menu/edit/:id" element={<ProtectedRoute needsPermission={['menu.update']}><MenuEdit /></ProtectedRoute>} />
              
              <Route path="/image/" element={<ProtectedRoute needsPermission={['image.getAll']}><Image /></ProtectedRoute>} />
              <Route path="/image/create" element={<ProtectedRoute needsPermission={['image.create']}><ImageCreate /></ProtectedRoute>} />
              <Route path="/image/edit/:id" element={<ProtectedRoute needsPermission={['image.edit']}><ImageEdit /></ProtectedRoute>} />

              <Route path="/album/" element={<ProtectedRoute needsPermission={['album.getAll']}><Album /></ProtectedRoute>} />
              <Route path="/album/create" element={<ProtectedRoute needsPermission={['album.create']}><AlbumCreate /></ProtectedRoute>} />
              <Route path="/album/edit/:id" element={<ProtectedRoute needsPermission={['album.edit']}><AlbumEdit /></ProtectedRoute>} />


              <Route path="/appointment/" element={<ProtectedRoute needsPermission={['appointment.getAll']}><Appointment /></ProtectedRoute>} />
              <Route path="/appointment/create" element={<ProtectedRoute needsPermission={['appointment.create']}><AppointmentCreate /></ProtectedRoute>} />
              <Route path="/appointment/read/:id" element={<ProtectedRoute needsPermission={['appointment.details']}><AppointmentRead /></ProtectedRoute>} />
              <Route path="/appointment/edit/:id" element={<ProtectedRoute needsPermission={['appointment.update']}><AppointmentEdit /></ProtectedRoute>} />

              <Route path="/news" element={<ProtectedRoute needsPermission={['news.getAll']}><News /></ProtectedRoute>} />
              <Route path="/news/create" element={<ProtectedRoute needsPermission={['news.create']}><NewsCreate /></ProtectedRoute>} />
              <Route path="/news/read/:id" element={<ProtectedRoute needsPermission={['news.details']}><NewsRead /></ProtectedRoute>} />
              <Route path="/news/edit/:id" element={<ProtectedRoute needsPermission={['news.update']}><NewsEdit /></ProtectedRoute>} />

              <Route path="/team" element={<ProtectedRoute needsPermission={['teams.getAll']}><Team /></ProtectedRoute>} />
              <Route path="/team/create" element={<ProtectedRoute needsPermission={['teams.create']}><TeamCreate /></ProtectedRoute>} />
              <Route path="/team/edit/:id" element={<ProtectedRoute needsPermission={['teams.update']}><TeamEdit /></ProtectedRoute>} />

              <Route path="/department" element={<ProtectedRoute needsPermission={['department.getAll']}><Department /></ProtectedRoute>} />
              <Route path="/department/create" element={<ProtectedRoute needsPermission={['department.create']}><DepartmentCreate /></ProtectedRoute>} />
              <Route path="/department/read/:id" element={<ProtectedRoute needsPermission={['department.details']}><DepartmentRead /></ProtectedRoute>} />
              <Route path="/department/edit/:id" element={<ProtectedRoute needsPermission={['department.update']}><DepartmentEdit /></ProtectedRoute>} />

              <Route path="/event" element={<ProtectedRoute needsPermission={['event.getAll']}><Event /></ProtectedRoute>} />
              <Route path="/event/create" element={<ProtectedRoute needsPermission={['event.create']}><EventCreate /></ProtectedRoute>} />
              <Route path="/event/read/:id" element={<ProtectedRoute needsPermission={['event.details']}><EventRead /></ProtectedRoute>} />
              <Route path="/event/edit/:id" element={<ProtectedRoute needsPermission={['event.update']}><EventEdit /></ProtectedRoute>} />

              <Route path="/special-doctor" element={<ProtectedRoute needsPermission={['speciality.getAll']}><SpecialDoctor /></ProtectedRoute>} />
              <Route path="/special-doctor/create" element={<ProtectedRoute needsPermission={['speciality.create']}><SpecialDoctorCreate /></ProtectedRoute>} />
              <Route path="/special-doctor/read/:id" element={<ProtectedRoute needsPermission={['speciality.details']}><SpecialDoctorRead /></ProtectedRoute>} />
              <Route path="/special-doctor/edit/:id" element={<ProtectedRoute needsPermission={['speciality.update']}><SpecialDoctorEdit /></ProtectedRoute>} />

              <Route path="/doctor" element={<ProtectedRoute needsPermission={['doctor.getAll']}><Doctor /></ProtectedRoute>} />
              <Route path="/doctor/create" element={<ProtectedRoute needsPermission={['doctor.create']}><DoctorCreate /></ProtectedRoute>} />
              <Route path="/doctor/read/:id" element={<ProtectedRoute needsPermission={['doctor.details']}><DoctorRead /></ProtectedRoute>} />
              <Route path="/doctor/edit/:id" element={<ProtectedRoute needsPermission={['doctor.update']}><DoctorEdit /></ProtectedRoute>} />

              <Route path="/doctor-visit" element={<ProtectedRoute needsPermission={['visit.getAll']}><DoctorVisit /></ProtectedRoute>} />
              <Route path="/doctor-visit/create" element={<ProtectedRoute needsPermission={['visit.create']}><DoctorVisitCreate /></ProtectedRoute>} />
              <Route path="/doctor-visit/edit/:id" element={<ProtectedRoute needsPermission={['visit.details']}><DoctorVisitEdit /></ProtectedRoute>} />
              <Route path="/doctor-visit/calender/:id" element={<ProtectedRoute needsPermission={['visit.update']}><DoctorVisitCalender /></ProtectedRoute>} />
              <Route path="/doctor-visit/delete-multiple" element={<ProtectedRoute needsPermission={['visit.update']}><DeleteMultiple /></ProtectedRoute>} />

              <Route path="/treatment" element={<ProtectedRoute needsPermission={['treatment.getAll']}><Treatment /></ProtectedRoute>} />
              <Route path="/treatment/create" element={<ProtectedRoute needsPermission={['treatment.create']}><TreatmentCreate /></ProtectedRoute>} />
              <Route path="/treatment/read/:id" element={<ProtectedRoute needsPermission={['treatment.details']}><TreatmentRead /></ProtectedRoute>} />
              <Route path="/treatment/edit/:id" element={<ProtectedRoute needsPermission={['treatment.update']}><TreatmentEdit /></ProtectedRoute>} />

              <Route path="/service" element={<ProtectedRoute needsPermission={['service.getAll']}><Service /></ProtectedRoute>} />
              <Route path="/service/create" element={<ProtectedRoute needsPermission={['service.create']}><ServiceCreate /></ProtectedRoute>} />
              <Route path="/service/read/:id" element={<ProtectedRoute needsPermission={['service.details']}><ServiceRead /></ProtectedRoute>} />
              <Route path="/service/edit/:id" element={<ProtectedRoute needsPermission={['service.update']}><ServiceEdit /></ProtectedRoute>} />

              <Route path="/package" element={<ProtectedRoute needsPermission={['package.getAll']}><Package /></ProtectedRoute>} />
              <Route path="/package/create" element={<ProtectedRoute needsPermission={['package.create']}><PackageCreate /></ProtectedRoute>} />
              <Route path="/package/read/:id" element={<ProtectedRoute needsPermission={['package.details']}><PackageRead /></ProtectedRoute>} />
              <Route path="/package/edit/:id" element={<ProtectedRoute needsPermission={['package.update']}><PackageEdit /></ProtectedRoute>} />

              <Route path="/price" element={<ProtectedRoute needsPermission={['price.getAll']}><Price /></ProtectedRoute>} />
              <Route path="/price/create" element={<ProtectedRoute needsPermission={['price.create']}><PriceCreate /></ProtectedRoute>} />
              <Route path="/price/read/:id" element={<ProtectedRoute needsPermission={['price.details']}><PriceRead /></ProtectedRoute>} />
              <Route path="/price/edit/:id" element={<ProtectedRoute needsPermission={['price.update']}><PriceEdit /></ProtectedRoute>} />

              <Route path="/publication" element={<ProtectedRoute needsPermission={['publication.getAll']}><Publication /></ProtectedRoute>} />
              <Route path="/publication/create" element={<ProtectedRoute needsPermission={['publication.create']}><PublicationCreate /></ProtectedRoute>} />
              <Route path="/publication/read/:id" element={<ProtectedRoute needsPermission={['publication.details']}><PublicationRead /></ProtectedRoute>} />
              <Route path="/publication/edit/:id" element={<ProtectedRoute needsPermission={['publication.update']}><PublicationEdit /></ProtectedRoute>} />

              <Route path="/page" element={<ProtectedRoute needsPermission={['page.getAll']}><Page /></ProtectedRoute>} />
              <Route path="/page/create" element={<ProtectedRoute needsPermission={['page.create']}><PageCreate /></ProtectedRoute>} />
              <Route path="/page/read/:id" element={<ProtectedRoute needsPermission={['page.details']}><PageRead /></ProtectedRoute>} />
              <Route path="/page/edit/:id" element={<ProtectedRoute needsPermission={['page.update']}><PageEdit /></ProtectedRoute>} />

              <Route path="/slider" element={<ProtectedRoute needsPermission={['slider.getAll']}><Slider /></ProtectedRoute>} />
              <Route path="/slider/create" element={<ProtectedRoute needsPermission={['slider.create']}><SliderCreate /></ProtectedRoute>} />
              <Route path="/slider/read/:id" element={<ProtectedRoute needsPermission={['slider.details']}><SliderRead /></ProtectedRoute>} />
              <Route path="/slider/edit/:id" element={<ProtectedRoute needsPermission={['slider.update']}><SliderEdit /></ProtectedRoute>} />

              <Route path="/room" element={<ProtectedRoute needsPermission={['room.getAll']}><Room /></ProtectedRoute>} />
              <Route path="/room/create" element={<ProtectedRoute needsPermission={['room.create']}><RoomCreate /></ProtectedRoute>} />
              <Route path="/room/read/:id" element={<ProtectedRoute needsPermission={['room.details']}><RoomRead /></ProtectedRoute>} />
              <Route path="/room/edit/:id" element={<ProtectedRoute needsPermission={['room.update']}><RoomEdit /></ProtectedRoute>} />

              <Route path="/booking" element={<ProtectedRoute needsPermission={['booking.getAll']}><Booking /></ProtectedRoute>} />
              <Route path="/booking/create" element={<ProtectedRoute needsPermission={['booking.create']}><BookingCreate /></ProtectedRoute>} />
              <Route path="/booking/read/:id" element={<ProtectedRoute needsPermission={['booking.details']}><BookingRead /></ProtectedRoute>} />
              <Route path="/booking/edit/:id" element={<ProtectedRoute needsPermission={['booking.update']}><BookingEdit /></ProtectedRoute>} />

              <Route path="/holiday" element={<ProtectedRoute needsPermission={['holiday.getAll']}><Holiday /></ProtectedRoute>} />
              <Route path="/holiday/create" element={<ProtectedRoute needsPermission={['holiday.create']}><HolidayCreate /></ProtectedRoute>} />
              <Route path="/holiday/edit/:id" element={<ProtectedRoute needsPermission={['holiday.update']}><HolidayEdit /></ProtectedRoute>} />
              <Route path="/holiday/calender" element={<ProtectedRoute needsPermission={['holiday.getAllCalender']}><Calender /></ProtectedRoute>} />

              <Route path="/job-post" element={<ProtectedRoute needsPermission={['job.getAll']}><JobPost /></ProtectedRoute>} />
              <Route path="/job-post/create" element={<ProtectedRoute needsPermission={['job.create']}><JobPostCreate /></ProtectedRoute>} />
              <Route path="/job-post/read/:id" element={<ProtectedRoute needsPermission={['job.details']}><JobPostRead /></ProtectedRoute>} />
              <Route path="/job-post/edit/:id" element={<ProtectedRoute needsPermission={['job.update']}><JobPostEdit /></ProtectedRoute>} />
              
              <Route path="/inner-page" element={<ProtectedRoute needsPermission={['resource.getAll']}><InnerPage /></ProtectedRoute>} />
              <Route path="/inner-page/create" element={<ProtectedRoute needsPermission={['resource.create']}><InnerPageCreate /></ProtectedRoute>} />
              <Route path="/inner-page/read/:id" element={<ProtectedRoute needsPermission={['resource.details']}><InnerPageRead /></ProtectedRoute>} />
              <Route path="/inner-page/edit/:id" element={<ProtectedRoute needsPermission={['resource.update']}><InnerPageEdit /></ProtectedRoute>} />

              <Route path="/user" element={<ProtectedRoute needsPermission={['auth.getAll']}><User /></ProtectedRoute>} />
              <Route path="/user/create" element={<ProtectedRoute needsPermission={['auth.register.admin']}><UserCreate /></ProtectedRoute>} />

              <Route path="/role" element={<ProtectedRoute needsPermission={['role.details']}><Role /></ProtectedRoute>}/>
              <Route path="/role/create" element={<ProtectedRoute needsPermission={['role.create']}><RoleCreate /></ProtectedRoute>}/>
              <Route path="/role/permission/:id" element={<ProtectedRoute needsPermission={['role.givePermissionToRole']}><RolePermission /></ProtectedRoute>}/>
              <Route path="/role/revoke-permission/:id" element={<ProtectedRoute needsPermission={['role.revokePermissionFromRole']}><RoleRevokePermission /></ProtectedRoute>}/>
              <Route path="/role/assign-role/:id" element={<ProtectedRoute needsPermission={['role.assingRoleToUser']}><RoleAssignToUser /></ProtectedRoute>}/>

              <Route path="/assign-user-role-list" element={<ProtectedRoute needsPermission={['role.userRoles']}><RoleUserList /></ProtectedRoute>}/>
              <Route path="/assign-user-role-list/delete-role/:id" element={<ProtectedRoute needsPermission={['role.removeRoleFromUser']}><DeleteRole /></ProtectedRoute>}/>

              <Route path="/activity/log" element={<ProtectedRoute needsPermission={['activity.getAll']}><ActivityLog /></ProtectedRoute>}/>

              <Route path="*" element={<NotFound />}/>

              <Route path="/productList" element={<ProductList />} />
              <Route path="/iconList" element={<Icon />} />
            </Routes>
        </div>
      </BrowserRouter>
    </NavProvider>
  );
};
export default App;