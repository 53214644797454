import React ,{useState,useEffect} from 'react'
import './auth.css';
import { Link , useNavigate, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import logo from '../../images/Ibrahim-logo.png'
import emailLogo from '../../images/email.svg'
import passLogo from '../../images/pass.svg'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ResetPassword = () => {
    const { register, handleSubmit } = useForm();
    const [data, setData] = useState('');
    const navigate = useNavigate();
    const [getItem, setItems] = useState([]);
    const [getError, setError] = useState({})
    const [getAuthError, setAuthError] = useState(false)
    const [getConfimationError, setConfimationError] = useState(false)
    const [getReset,setReset] = useState('');

    const onSubmit = (data, e) => {
        setError({})
        setAuthError(false)
        // data.image_url = data.image_url[0];
        setData(data);
        
        if(data.password != data.confirm_password){
            setConfimationError(true)
            return
        }
        // return
        http.post('/auth/reset-forgot-password',data)
        .then((response)=>{
            if(response.status == 200){
                setReset(true)              
            }  
            return
            }).then(()=>{
                setTimeout(()=>{
                    setReset(false)
                    navigate('/login')
                },2000)  
            })
            .catch((err)=> {
                console.log(err.response.data.message)
                if(err.response.data.message == "Validation Error."){
                    let errs = err.response.data.data;
                    let keys = Object.keys(errs)
                    console.log(keys);
                    let errors = {};
                    keys.map((key)=> errors[key] = errs[key][0])
                    console.info(errors);
                    setError(errors)
                }
                else{
                    setAuthError(true)
                }
            })
        }
        // console.log(getAuthError)
    const onError = (errors, e) => console.log(errors, e);
  
 

    const handleChenge = () => {
        setError('');
        setAuthError(false);
    }

    
    return (
        <> {     
            (getReset == true) ? 
                (        
                    <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0',zIndex:'111111'}}>
                        <Toast.Body >Your password has been reset successfully</Toast.Body>
                    </Toast>
                )
            :null
        }
    <div className="form-design">
        <Form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
            <img src={logo} style={{marginBottom:"44px"}}/>
            {getAuthError ?
                <div className="mt-4">
                    <p className="authError">
                        The Credential is not Correct
                    </p>
                </div>
                :
                null
            }
            <Form.Group className="input_wrapper">
                {/* <Form.Label className="form_label">Email</Form.Label> */}
                <Form.Control
                    type="text"
                    name="token"
                    className="code email"
                    // value={id}
                    placeholder="Enter the Code"
                    {...register("token")}
                />
                <div class="input_field_icon">
                    {/* <img alt="content_img" src={emailLogo}/> */}
                    <span>OTP</span>
                </div>
                {getError.token? 
                <span className="text-danger warn" style={{marginTop:"2px"}}>
                    {getError.token}
                </span> : null
                }
            </Form.Group>

            <Form.Group  className="input_wrapper">
                {/* <Form.Label className="form_label">Password</Form.Label> */}
                <Form.Control
                    type="password"
                    name="password"
                    className="password"
                    // value={id}
                    placeholder="Enter the  password"
                    {...register("password")}
                />
                <div class="input_field_icon">
                    <img alt="content_img" src={passLogo}/>
                </div>
                {getError.password? 
                <span className="text-danger warn">
                    {getError.password}
                </span> : null
                }
                {getConfimationError? 
                <span className="text-danger warn">
                    The Password is not match
                </span> : null
                }
            </Form.Group>

            <Form.Group  className="input_wrapper">
                {/* <Form.Label className="form_label">Password</Form.Label> */}
                <Form.Control
                    type="password"
                    name="confirm_password"
                    className="password"
                    // value={id}
                    placeholder="Enter the confirm password"
                    {...register("confirm_password")}
                />
                <div class="input_field_icon">
                    <img alt="content_img" src={passLogo}/>
                </div>
                {getError.confirm_password? 
                <span className="text-danger warn">
                    {getError.confirm_password}
                </span> : null
                }
                {getConfimationError? 
                <span className="text-danger warn">
                    The Password is not match
                </span> : null
                }

            </Form.Group>
            <div className="mt-4">
                <Button className="login_btn" variant="primary" onClick={handleChenge} type="submit">
                    submit
                </Button>
                <Link to="/login" className="forgot-password">Back to Login Page</Link>
            </div>
        </Form>
    </div>
    </>
  )
}

export default ResetPassword;