import React ,{useState,useEffect} from 'react'
import './role.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang,department} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const RoleCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [roles, setRoles] = useState('');
    const [keyss,setKeys] = useState([])
    const [valuess,setvalues] = useState([])
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllPermissions();
    },[]);

    const fetchAllPermissions = () => {
        http.get('/permission/admin/all',{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then(res=>{
            console.log(res.data.data)
            setPermissions(res.data.data);
        })
    }
    console.log(cookies.get('userAuth').token);
    const onSubmit = (data, e) => {       
        data = {...data, ...roles};
        data.user_id = cookies.get('userAuth').id
        setLoader(true)
        http.post('/role/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/role");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            console.log(err)
            // console.log(Object.keys(err.response.data.data))
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleChange = (e) =>{
        console.log(e);
        let role = {...roles};
        role[e.target.name] = e.target.value
        setRoles(role);
    }
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Role</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.name? 
                    <span className="text-danger">
                      {getErrorObj.name}
                    </span> : null
                    }
                </Form.Group>

                {/* <Form.Group>
                    <Form.Label className="form_label">Permission</Form.Label>
                    <CheckPicker  name="holiday" 
                        data={keys?.map((item,ele) => ({ label: day[item], value: ele }))} 
                        style={{ width: "100%" }} 
                        //   {...register("dr_speciality_id")} 
                        onChange={(value) => setHolidays({...holidays, "holiday": value})} 
                    />

                    {getErrorObj.days? 
                    <span className="text-danger">
                    {getErrorObj.days}
                    </span> : null
                    }
                </Form.Group> */}

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/role">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default RoleCreate