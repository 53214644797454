import React,{useState,useEffect } from 'react';
import './appointment.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';
import AppointmentDownload from './AppointmentDownload';
import { FaFileImage } from 'react-icons/fa';
import pdfImage from "../../images/pdf.png";
import jpgImage from "../../images/jpg.png";
import docxImage from "../../images/docx.png";
import pngImage from "../../images/png.png";
import { BsFillCursorFill } from "react-icons/bs";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

const Appointment = () => {
    const [show, setShow] = useState(false);
    const [fileShow, setFileShow] = useState(false);
    const [id, setId] = useState('');
    const [getAppData, setAppData] = useState({});
    const [appointments, setAppointments] = useState([]);
    const [getDeleteAppointment, setDeleteAppointment] = useState(false);
    const [search, setSearch] = useState("");
    const [filterAppointments, setFilterAppointments] = useState([]);

    const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();


    const cookies = new Cookies();

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    // console.log('filterAppointments',filterAppointments)

    const originalUrl = process.env.REACT_APP_BASE_URL
    let image = originalUrl.replace("/api", "");
    
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };
    const handleCloseFileShow = () => setFileShow(false);

    const handleShowFileShow = (data) => {
        let appointmentData
        if(data.files.length > 0){
            appointmentData = {
                "id":data?.id,
                "name" : data?.userfor?.name,
                "date_time" : formateDate(data?.date_time),
                "files": data?.files
            }
        }
        setAppData(appointmentData)

        setFileShow(true);
    };

    const deleteAppointment = (id) => 
    {
        http.get('/appointment/delete/'+id,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((res)=>{
        setDeleteAppointment(true);

            setTimeout(()=>{
                setDeleteAppointment(false)}
            ,2000) 
                
        })
        handleClose();
    };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllAppointments(getCurrentPage);
    },[getDeleteAppointment]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/appointment/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterAppointments(response.data.data.data);
        })
        
        // })
        // fetchAllAppointments2()
    },[search]);

    // +++++++++++++++++++
    const fetchAllAppointments = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		await http.get(`/appointment/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            setFilterAppointments(response.data.data.data)
            setAppointments(response.data.data.data);
            setTotalRows(response.data.data.total);
            setLoading(false);
        });
        
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		await http.get(`/appointment/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{        
            setAppointments(response.data.data.data);
            setFilterAppointments(response.data.data.data)
            setPerPage(perPage);
            setLoading(false);
        });
        
	};

	useEffect(() => {
		fetchAllAppointments(1); // fetch page 1 of users
	}, []);

    // +++++++++++++++++++
    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }

    const iconImage = (type) =>{
        if(type == "image/png"){
            return pngImage;
        }
        else if(type == "image/jpg" || type == "image/jpeg"){
            return jpgImage;
        }
        else if(type == "application/pdf")
        {
            return pdfImage;
        }
    }

    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable:true
        },
        {
            name: "Gender",
            selector: row => (row.gender)?row.gender : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Doctor Name",
            selector: row => (row.doctor_id)?row.doctor.name:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Date Time",
            selector: row => (row.date_time)?formateDate(row.date_time):<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Payment Status",
            selector: row => (row.payment_status == "UNPAID")? 
                            <Badge bg="danger" text="light">{row.payment_status}</Badge>
                            :
                            <Badge bg="success" text="light">{row.payment_status}</Badge>,
            sortable:true
        },
        {
            name: "Status",
            selector: row => (row.status == "CONFIRM")
                            ?<Badge bg="success" text="light">{row.status}</Badge>
                            : (row.status == "PENDING") ? 
                            <Badge bg="warning" text="light">{row.status}</Badge>
                            :<Badge bg="danger" text="light">{row.status}</Badge>,
            sortable:true
        }, 
        {
            name: "File URL",
            selector: row => (row.file_url)?
                <a href={image+row.file_url} target="_blank"><BsFillCursorFill/></a>
                :
                <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        }, 
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                {
                    row?.files.length > 0 &&
                    <Button className="action__btn" onClick={() =>handleShowFileShow(row)} variant="success">
                        <FaFileImage />
                    </Button>
                }

                <ComponentGuard needsPermission={['appointment.details']}>
                    <Link to={"read/"+row.id}>
                        <Button className="action__btn" variant="info">
                            <GrView/>
                        </Button>
                    </Link>
                </ComponentGuard>

                <ComponentGuard needsPermission={['appointment.update']}>
                    <Link to={"edit/"+row.id}>
                        <Button className="action__btn" variant="primary">
                            <AiTwotoneEdit/>
                        </Button>
                    </Link>
                </ComponentGuard>

                <ComponentGuard needsPermission={['appointment.delete']}>
                    <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                        <MdDelete/>
                    </Button>
                </ComponentGuard>

                </>
            ),            
        }
    ]

    const handleDownloadZip = async(appointmentId) => {
        try {
            const response = await http.get(`/appointment/download-zip/${appointmentId}`, {
                headers: {
                    "Authorization": "Bearer " + cookies.get('userAuth').token,
                },
                responseType: 'blob' // Important: Set the response type to 'blob'
            });
            
    
            // Extract the filename from the Content-Disposition header if available
            const contentDisposition = response.headers['content-disposition'];
            let filename = `${getAppData?.name}-${getAppData?.date_time}.zip`;
            if (contentDisposition) {
                const matches = /filename="([^"]+)"/.exec(contentDisposition);
                if (matches && matches[1]) {
                    filename = matches[1];
                }
            }
    
            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Use FileSaver to save the blob as a file
            saveAs(blob, filename);
            setFileShow(false)
    
        } catch (error) {
            console.error("Error downloading ZIP file:", error);
            // Handle error (e.g., show a notification or alert to the user)
        }
    };


    return (
        <>
        <Layout>

            {     
                (getDeleteAppointment) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0',zIndex:'111111'}}>
                            <Toast.Body >Appointment Deleted</Toast.Body>
                        </Toast>
                    )
                :null
            }

            <ComponentGuard needsPermission={['appointment.download']}>
                <AppointmentDownload/>
            </ComponentGuard>


            <ComponentGuard needsPermission={['appointment.create']}>
                <Link to="create">
                    <Button className="create_btn btn btn-primary" variant="primary">Add Appointment</Button>
                </Link>
            </ComponentGuard>

            <DataTable 
                title="Appointment List"
                columns={columns} 
                data={filterAppointments} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input 
                    type="text" 
                    placeholder="Search Here" 
                    className="form-control search_btn"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}
                    />
                }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/appointment">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteAppointment(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
            
            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={fileShow} onHide={handleCloseFileShow}>
                <Modal.Header closeButton>
                    <Modal.Title>{getAppData?.name} - {getAppData?.date_time}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="file-list">
                        {getAppData?.files?.map((d)=>(
                             <div class="file-item">
                                <a href={image+d?.url} target='_blank'>   
                                     <img src={iconImage(d.mimeType)} alt="PDF Icon" class="file-icon"/>
                                     <span class="file-name">{d?.url?.split('/').pop()}</span>
                                 </a> 
                                <span class="file-size">{(d.sizeInBytes/1024).toFixed(2)} KB</span>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="/appointment">
                        <Button className="action__btn" variant="secondary" onClick={handleCloseFileShow}>
                            Close
                        </Button>
                        <Button className="action__btn" variant="success" onClick={()=>handleDownloadZip(getAppData.id)}>
                            Download All
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </Layout>
        </>
    );
};

export default Appointment;