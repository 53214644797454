import React,{useState,useEffect} from 'react'
import './jobpost.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import { SelectPicker, DatePicker} from 'rsuite';
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';
const JobPostRead = () => {
    const [jobPost, setJobPost] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    const cookies = new Cookies()
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(jobPost)
    useEffect(()=>{
        fetchSingleJobPost();
    },[]);

    const fetchSingleJobPost = () => {
        http.get('job/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setJobPost(res.data.data);
        })
    }
    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }
  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {jobPost == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.title)?jobPost.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Sub Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.sub_title)?jobPost.sub_title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Department:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.department_id)?jobPost.department.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Employment:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.employment)?jobPost.employment : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Location:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.location)?jobPost.location : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Workplace:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.workplace)?jobPost.workplace : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Email:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.email)?jobPost.email : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Application Link:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.application_link)?jobPost.application_link : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Source:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.source)?jobPost.source : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Salary:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.salary)?jobPost.salary : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Vacancy:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.vacancy)?jobPost.vacancy : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.image_url)?
                             <p>
                             {
                               (jobPost.image_url) ? <a href={image+'/'+jobPost.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   

                    <div className="col-sm-6">
                        <label>Responsibilities:</label>
                    </div>
                        {jobPost.responsibilities?
                        
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={jobPost.responsibilities}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }


                    <div className="col-sm-6">
                        <label>Description:</label>
                    </div>
                        {jobPost.description?
                        
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={jobPost.description}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }


                    <div className="col-sm-6">
                        <label>Experience:</label>
                    </div>
                        {jobPost.experience?
                        
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={jobPost.experience}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }


                    <div className="col-sm-6">
                        <label>Education:</label>
                    </div>
                        {jobPost.education?
                        
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={jobPost.education}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }


                    <div className="col-sm-6">
                        <label>Requirements:</label>
                    </div>
                        {jobPost.requirements?
                        
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={jobPost.requirements}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }


                    <div className="col-sm-6">
                        <label>Benefits:</label>
                    </div>
                        {jobPost.benefits?
                        
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={jobPost.benefits}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }


                    <div className="col-sm-6">
                        <label>Deadline:</label>
                    </div>
                    <div className="col-sm-6">
                    {(jobPost.deadline)? 
                            formateDate(jobPost.deadline)
                             : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.lang_parent)?jobPost.lang_parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(jobPost.lang)?jobPost.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['job.update']}>
            <Link to={"/job-post/edit/"+jobPost.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default JobPostRead