import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './department.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const DepartmentEdit = () => {
    const {id} = useParams()
    const [departments, setDepartments] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [getLangError,setLangError] = useState('');
    const [parents, setParents] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const [teams, setTeams] = useState([]);

    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/department/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }

    useEffect(()=>{
      if(departments.team_id)
        getTeamSearch()
    },[departments]);
  
  
  const getTeamSearch = (val = "") =>{
      let val1 = val.length > 0 ? val : '*';
      if(val.length > 0) {
        http.get(`/department/team/search/${val}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setTeams(response.data.data);
        })
      }else if(departments.team_id){
        http.get(`/department/team/get/${departments.team_id}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setTeams({'data': [response.data.data]});
        })
      } else {
        http.get(`/department/team/search/${val1}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setTeams({'data': []});
        })
      }
  }

    useEffect(()=>{
      fetchEditDepartments();
    },[]);

    const fetchEditDepartments = () => {
        http.get('department/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setDepartments(removeEmpty(res.data.data));
        })
    }
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        delete departments.slug;
        const image_url = data.image_url[0];
        delete departments.image_url; 
        data = {...data, ...departments};
        data.user_id = cookies.get('userAuth').id
        // data = removeEmpty(data)
        data.image_url = image_url;
        setLoader(true)

      http.post('/department/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/department");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
        if(err.response.data.message == "Validation Error."){
          let errs = err.response.data.data;
          let keys = Object.keys(errs)
          console.log(keys);
          let errors = {};
          keys.map((key)=> errors[key] = errs[key][0])
          console.info(errors);
          setError(err.response.data)
          
          setErrorObj(errors)
        }
        else{
          setLangError(err.response.data.data)
        }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
    let department = {...departments};
    department[e.target.name] = e.target.value
    setDepartments(department);
    }

    function handleKeyPress(e) {
      // console.log(e.code)
      if (['Minus', 'NumpadSubtract'].includes(e.code)) {
          console.log(e.code);
          e.target.value = e.target.value.slice(0, -1)
      }
      else if(/^0/.test(e.target.value)) {
          e.target.value = e.target.value.replace(/^0/, "")
      }
      let intpart = e.target.value.split(".")[0];
      let decimal = e.target.value.split(".")[1].slice(0, 4);
      decimal = decimal.length > 0 ? "."+decimal : "";
      e.target.value = intpart + decimal;
      console.log(e.target.value)
      }

  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {departments == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Department</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={departments.title}
                      placeholder="Enter title"
                    //   {...register("title")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
              <Form.Label className="form_label">Short Description</Form.Label>
                  <Form.Control as="textarea" rows={5}
                      type="text"
                      name="sub_title"
                      defaultValue={departments.sub_title}
                      placeholder="Enter Short Description"
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.sub_title? 
                  <span className="text-danger">
                    {getErrorObj.sub_title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Image url</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (departments.image_url) ? <a href={image+'/'+departments.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                    <Form.Label className="form_label">Team</Form.Label>
                    <SelectPicker 
                          defaultValue={departments.team_id}
                          data={teams.data?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getTeamSearch(val)}
                          onChange={(value) => setDepartments({...departments, "team_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

              {/* <Form.Group>
                    <Form.Label className="form_label">Parent</Form.Label>
                    <SelectPicker 
                          defaultValue={departments.parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setDepartments({...departments, "parent_id": value})}

                      />
                    {getErrorObj.parent_id? 
                    <span className="text-danger">
                      {getErrorObj.parent_id}
                    </span> : null
                    }
                </Form.Group> */}

                <Form.Group>
                    <Form.Label className="form_label">Order</Form.Label>
                    <Form.Control
                        type="number"
                        defaultValue={departments.order}
                        name="order"
                        step="1.00"
                        placeholder="Enter order"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("minute_per_patient")}
                        onChange={(e) => handleChange(e)}
                        onWheel={ event => event.currentTarget.blur() }

                    />
                    {getErrorObj.order? 
                    <span className="text-danger">
                      {getErrorObj.order}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          defaultValue={departments.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setDepartments({...departments, "lang_parent_id": value})}

                      />
                      {getErrorObj.lang_parent_id? 
                      <span className="text-danger">
                        {getErrorObj.lang_parent_id}
                      </span> : null
                      }
                      {getLangError? 
                      <span className="text-danger">
                        {getLangError}
                      </span> : null
                      }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          defaultValue={departments.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setDepartments({...departments, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor 
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        data={departments.description}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setDepartments({...departments, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/department">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default DepartmentEdit