import React,{useState,useEffect} from 'react'
import './room.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import { TagInput } from 'rsuite';
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';

const RoomRead = () => {
    const [rooms, setRooms] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL
    const cookies = new Cookies();
    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(rooms)
    useEffect(()=>{
        fetchSingleRooms();
    },[]);

    const fetchSingleRooms = () => {
        http.get('room/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setRooms(res.data.data);
        })
    }

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {rooms == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Name:</label>
                    </div>
                    <div className="col-sm-6">
                        {(rooms.name)?rooms.name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Room Number:</label>
                    </div>
                    <div className="col-sm-6">
                        {(rooms.number)?rooms.number : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(rooms.image_url)?
                             <p>
                             {
                               (rooms.image_url) ? <a href={image+'/'+rooms.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   

                    <div className="col-sm-6">
                        <label>Description:</label>
                    </div>
                     {rooms.description?
                     
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={rooms.description}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }

                </div>
            }
            </div>
        </div>
        
        <ComponentGuard needsPermission={['room.update']}>
            <Link to={"/room/edit/"+rooms.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default RoomRead