import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './doctorVisit.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const DoctorVisitEdit = () => {
    const {id} = useParams()
    const [events, setEvents] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [getFrom,setFrom] = useState('')
    const [doctorsVisit,setDoctorsVisit] = useState('');
    const [getDoc,setDoc] = useState([]);
    const [getLoader,setLoader] = useState(false);

    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchEditVisitDoctor();
    },[]);

    const fetchEditVisitDoctor = () => {
        http.get('visit/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            console.log(res);
          if(res.data.data.from){
            let fromDate = res.data.data.from
            let tempDateFrom = new Date(fromDate).toUTCString().toString();
            let dateFrom =  tempDateFrom.substring(0, 16)
            let timeFrom =  tempDateFrom.substring(17, 26) 
            res.data.data.from = formateDate(dateFrom+" "+timeFrom+" "+"GMT+0600 (Bangladesh Standard Time)")
            // console.log(res.data.data.from)
          }
          if(res.data.data.to){
            let toDate = res.data.data.to
            let tempDateTo = new Date(toDate).toUTCString().toString();
            let dateTo =  tempDateTo.substring(0, 16)
            let timeTo =  tempDateTo.substring(17, 26) 
            res.data.data.to = formateDate(dateTo+" "+timeTo+" "+"GMT+0600 (Bangladesh Standard Time)")
          }
          console.table(res.data.data)
          setDoctorsVisit(removeEmpty(res.data.data));
        })
    }
    
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
        data = {...data, ...doctorsVisit};
        data.user_id = cookies.get('userAuth').id
        delete doctorsVisit.doctor.id;
        // data = removeEmpty(data)

        setLoader(true)
      http.post('/visit/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/doctor-visit");
        },500)
      })
      .catch((err)=> {
          setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          let errs = err.response.data.data;
          let keys = Object.keys(errs)
          console.log(keys);
          let errors = {};
          keys.map((key)=> errors[key] = errs[key][0])
          console.info(errors);
          setError(err.response.data)
          
          setErrorObj(errors)
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let doctorVisit = {...doctorsVisit};
    doctorVisit[e.target.name] = e.target.value
    setDoctorsVisit(doctorVisit);
}

const formateDate = (val) =>{
  console.log(val)
  let tempDate = new Date(val).toUTCString().toString();
  let fetchDate = tempDate.substring(5, 7);

  let monthConvert = 
  tempDate.substring(8, 11)=='Jan' ? "01" :
  tempDate.substring(8, 11)=='Feb' ? "02" :
  tempDate.substring(8, 11)=='Mar' ? "03" :
  tempDate.substring(8, 11)=='Apr' ? "04" :
  tempDate.substring(8, 11)=='May' ? "05" :
  tempDate.substring(8, 11)=='Jun' ? "06" :
  tempDate.substring(8, 11)=='Jul' ? "07" :
  tempDate.substring(8, 11)=='Aug' ? "08" :
  tempDate.substring(8, 11)=='Sep' ? "09" :
  tempDate.substring(8, 11)=='Oct' ? "10" :
  tempDate.substring(8, 11)=='Nov' ? "11" : 
  tempDate.substring(8, 11)=='Dec' ? "12" : null
  // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
  let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
  // console.log(date)

  let time = new Date(val).toLocaleTimeString('en-US',{
      hour12: false,
    });


  var combineDate = date +" "+ time;
  console.log(combineDate);
  return combineDate;

}

useEffect(()=>{
    console.log(doctorsVisit)
    if(doctorsVisit.doctor)
      getSearch()
  },[doctorsVisit]);


const getSearch = (val = "") =>{
    let val1 = val.length > 0 ? val : '*';
    if(val.length > 0) {
      http.get(`/doctor/search/${val}`, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then((response)=>{
        console.log(response);
        setDoc(response.data.data);
      })
    }else if(doctorsVisit.doctor_id){
      http.get(`/doctor/get/${doctorsVisit.doctor_id}`, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then((response)=>{
        console.log(response.data.data);
        setDoc({'data': [response.data.data]});
      })
    } else {
      console.log(val1)
      http.get(`/doctor/search/${val1}`, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then((response)=>{
        console.log(response.data.data);
        setDoc({'data': []});
      })
    }
}

function handleKeyPress(e) {
    // console.log(e.code)
    if (['Minus', 'NumpadSubtract'].includes(e.code)) {
        console.log(e.code);
        e.target.value = e.target.value.slice(0, -1)
    }
    else if(/^0/.test(e.target.value)) {
        e.target.value = e.target.value.replace(/^0/, "")
    }
    let intpart = e.target.value.split(".")[0];
    let decimal = e.target.value.split(".")[1].slice(0, 4);
    decimal = decimal.length > 0 ? "."+decimal : "";
    e.target.value = intpart + decimal;
    }

  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {doctorsVisit == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Visit Doctor</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
                    <Form.Label className="form_label">Max Visitor</Form.Label>
                    <Form.Control
                        type="number"
                        name="max_visitor"
                        step="0.01"
                        placeholder="Enter max visitor"
                        onKeyUp={(e) => handleKeyPress(e)}
                        defaultValue={doctorsVisit.max_visitor}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.max_visitor? 
                    <span className="text-danger">
                      {getErrorObj.max_visitor}
                    </span> : null
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form_label">Doctor</Form.Label>
                      <SelectPicker 
                          defaultValue={doctorsVisit.doctor_id}
                          name="doctor_id" 
                          data={getDoc.data?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getSearch(val)}
                          onChange={(value) => setDoctorsVisit({...doctorsVisit, "doctor_id": value})}
                      />
                    {getErrorObj.doctor_id? 
                    <span className="text-danger">
                      {getErrorObj.doctor_id}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">From Date</Form.Label>
                    <DatePicker
                        // defaultValue={(events.from) ? new Date(events.from) : ""}
                        defaultValue={(doctorsVisit.from) ? new Date(doctorsVisit.from) : ""}
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        style={{ width: "100%" }}
                        onChange={(value) => setDoctorsVisit({...doctorsVisit, "from": formateDate(value)})}
                    />
                    {getErrorObj.from? 
                    <span className="text-danger">
                      {getErrorObj.from}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">To Date</Form.Label>
                    <DatePicker
                        // defaultValue={(events.to) ? new Date(events.to) : ""}
                        defaultValue={(doctorsVisit.to) ? new Date(doctorsVisit.to) : ""}
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        style={{ width: "100%" }}
                        onChange={(value) => setDoctorsVisit({...doctorsVisit, "to": formateDate(value)})}
                    />
                    {getErrorObj.to? 
                    <span className="text-danger">
                      {getErrorObj.to}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/doctor-visit">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default DoctorVisitEdit