import React,{useState,useEffect } from 'react';
import { Button, Modal,Toast,Badge,Form } from "react-bootstrap";
import {SelectPicker,DatePicker} from "rsuite";
import { useForm } from "react-hook-form";
import Cookies from 'universal-cookie';
import http from "../../http"
import _ from "lodash";
import moment from "moment/moment";
import DataTableXlDownload from "../../components/DataTableXlDownload";


const AppointmentDownload = () => {
    const { register, handleSubmit,reset, setValue, getValues } = useForm();
    const [getDoc, setDoc] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [getVisit, setVisit] = useState([]);
    const [getTime, setTime] = useState([]);
    const [isActive, setisActive] = useState(false);
    const [getVisitId, setVisitId] = useState();
    const [getErrorObj, setErrorObj] = useState({});
    const [getLoader, setLoader] = useState(false);
    const [getError, setError] = useState("");
    const [selectedValueDoctor, setSelectedValueDoctor] = useState(null);  
    const [selectedValueDate, setSelectedValueDate] = useState(null);  
    // const [selectedValue, setSelectedValue] = useState(null);

    const cookies = new Cookies();
    const originalUrl = process.env.REACT_APP_BASE_URL
    let image = originalUrl.replace("/api", "");

    const getSearch = (val) => {
        if (val.length > 0) {
          http
            .get(`/doctor/search/${val}`, {
              headers: {
                Authorization: "Bearer " + cookies.get("userAuth").token,
              },
            })
            .then((response) => {
              setDoc(response.data.data.data);
            });
        } else if (appointments.doctor_id) {
          http
            .get(`/doctor/get/${appointments.doctor_id}`, {
              headers: {
                Authorization: "Bearer " + cookies.get("userAuth").token,
              },
            })
            .then((response) => {
              setDoc([response.data.data]);
            });
        } else {
          setDoc([]);
        }
      };

      useEffect(() => {
        if (appointments.doctor_id != null) {
          fetchDoctorVisit(appointments.doctor_id);
        }
      }, [appointments.doctor_id]);
    
      const fetchDoctorVisit = (val) => {
        http
          .get(`/visit/get/doctor?doctor_id=${val}`, {
            headers: {
              Authorization: "Bearer " + cookies.get("userAuth").token,
            },
          })
          .then((response) => {
            console.log(response.data.data);
            setVisit(response.data.data);
          });
      };

      const customDisable = (date) => {
        // getVisit[0].from
        const datePic = new Date(date).toISOString().split("T")[0];
        return !_.find(getVisit, (v) => v.from?.substring(0, 10) === datePic);
      };
    
      useEffect(() => {
        // console.log(getDoc[0]?.minute_per_patient);
        let minTimeVal = getDoc[0]?.minute_per_patient
          ? getDoc[0]?.minute_per_patient
          : 5;
        if (appointments.date && minTimeVal !== "undefined") {
          timeFormat(appointments.date, minTimeVal);
        }
      }, [appointments.date, getDoc[0]?.minute_per_patient]);
    
      const timeFormat = (val, min) => {
        console.log(val);
        console.log(getVisit);
        console.log(min);
    
        let data = _.find(getVisit, (v) => v.from?.substring(0, 10) === val);
        // let to = _.find(getVisit, (v)=> (v.to?.substring(0, 10)===val))
    
        console.log(data);
    
        let from = data?.from?.substring(11, 16);
        let to = data?.to?.substring(11, 16);
    
        console.log(typeof from, from, to, min);
        setisActive(true);
        var timeStops = getTimeStops(from, to, min);
        setTime(timeStops);
        // setFromHr(fromhr)
        // setFromMin(fromMin)
        // setToHr(tohr)
        // setToMin(toMin)
        setVisitId(data?.id);
      };

      useEffect(() => {
        getTimeStops();
      }, []);
      function getTimeStops(start, end, min) {
        var startTime = moment(start, "HH:mm");
        var endTime = moment(end, "HH:mm");
    
        if (endTime.isBefore(startTime)) {
          endTime.add(1, "day");
        }
    
        var timeStops = [];
    
        while (startTime <= endTime) {
          timeStops.push(new moment(startTime).format("HH:mm"));
          startTime.add(min, "minutes");
        }
        return timeStops;
      }

      const formateDate = (val) => {
        console.log(val);
        let tempDate = new Date(val).toUTCString().toString();
        // console.log(tempDate)
        let fetchDate = tempDate.substring(5, 7);
    
        let monthConvert =
          tempDate.substring(8, 11) == "Jan"
            ? "01"
            : tempDate.substring(8, 11) == "Feb"
            ? "02"
            : tempDate.substring(8, 11) == "Mar"
            ? "03"
            : tempDate.substring(8, 11) == "Apr"
            ? "04"
            : tempDate.substring(8, 11) == "May"
            ? "05"
            : tempDate.substring(8, 11) == "Jun"
            ? "06"
            : tempDate.substring(8, 11) == "Jul"
            ? "07"
            : tempDate.substring(8, 11) == "Aug"
            ? "08"
            : tempDate.substring(8, 11) == "Sep"
            ? "09"
            : tempDate.substring(8, 11) == "Oct"
            ? "10"
            : tempDate.substring(8, 11) == "Nov"
            ? "11"
            : tempDate.substring(8, 11) == "Dec"
            ? "12"
            : null;
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at "
        let date =
          tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate;
        // console.log(date)
    
        let time = new Date(val).toLocaleTimeString("en-US", {
          hour12: false,
        });
    
        var combineDate = date + " " + time;
        return date;
      };

      const formateDateForExcel = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }

      const onSubmit = (data, e) => {

        setErrorObj({});
        data = { ...data, ...appointments };
        console.log(data)
        
    
        setLoader(true);
        http
          .post("/appointment/patient-list-download", data, {
            headers: {
              Authorization: "Bearer " + cookies.get("userAuth").token,
            },
          })
          .then((res) => {
            console.log(res);

            // setSuccess(true);
            if(res.status == 200){
                setSelectedValueDoctor(null)
                setSelectedValueDate(null)
                let data = [];

                if(res?.data?.data.length > 0){
                    
                    res?.data?.data.map((e) =>
                      data.push({
                        "Doctor Name": e.doctor.name,
                        "Patient Name": e.name,
                        "Phone Number": e.userfor.phone,
                        "Gender": e.gender,
                        "Status": e.status,
                        "Payment Status": e.payment_status,
                        "Date Time": formateDateForExcel(e.date_time),
                        "Address": e.address,
                        "Report Link": e.file_url != null ? "Yes" : "No",
                      })
                    );
    
                    console.log(data,res?.data?.data[0]?.doctor.name)
                    // setexcelData(data);
                    // DataTableXlDownload(data,res?.data?.data[0]?.doctor.name);
                    DataTableXlDownload(data, res?.data?.data[0]?.doctor.name)
                    setLoader(false);

                }
                else{
                    setLoader(false);
                    alert("Appointment arn't available under these doctors.")

                }
            }
            
            // localStorage.setItem("success", true);
            // setLoader(false);
            
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
            // console.log(Object.keys(err.response.data.data))
            // console.log(err.response.data.message);
            let errs = err.response.data.data;
            let keys = Object.keys(errs);
            console.log(keys);
            let errors = {};
            keys.map((key) => (errors[key] = errs[key][0]));
            console.info(errors);
            setError(err.response.data);
    
            setErrorObj(errors);
          });
      };

   
  return (
    <>
      {getLoader && (
          <div className="preloader">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spin"></span>
              </div>
            </div>
          </div>
        )}

    <h6 className='pb-3'>Doctor Wise Appointment Download</h6>
    <Form onSubmit={handleSubmit(onSubmit)}>    
        <div className='d-lg-flex d-md-flex align-items-lg-center align-items-md-center'>
            <Form.Group className='col-lg-3 col-md-4 col-sm-12 pb-2'>
                <SelectPicker
                    name="doctor_id"
                    value={selectedValueDoctor}
                    data={getDoc?.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))}
                    style={{ width: "100%" }}
                    onSearch={(val) => getSearch(val)}
                    onChange={(value) =>{
                            setSelectedValueDoctor(value);
                            setAppointments({ ...appointments, "doctor_id": value })
                        }
                    }
                />
            </Form.Group>

            <Form.Group className='col-lg-3 col-md-4 col-sm-12 pb-2 mb-3-sm'>
                <DatePicker
                    oneTap
                    name="date_time"
                    value={selectedValueDate}
                    format="yyyy-MM-dd"
                    showWeekNumbers
                    style={{ width: "100%" }}
                    disabledDate={(date) => customDisable(date)}
                    onChange={(value) =>{
                        setSelectedValueDate(value)
                        setAppointments({ ...appointments, "date": formateDate(value) })}
                    }
                    />
                    {getErrorObj.date_time ? (
                    <span className="text-danger">{getErrorObj.date_time}</span>
                    ) : null}
            </Form.Group>

            <Button className="btn btn-primary mt-lg-0 ml-2 mb-2 mt-2" type="submit" variant="primary">Download</Button>

        </div>
            
    </Form>
    
    </>
  )
}

export default AppointmentDownload