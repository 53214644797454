import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm} from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';

const YoutubeLinkEdit = () => {
    const {id} = useParams()
    const [youtubeLink,setYoutubeLink] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const [getLangError,setLangError] = useState('');

    const originalUrl = process.env.REACT_APP_BASE_URL
    let image = originalUrl.replace("/api", "");

    console.log(image)

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });
    
    useEffect(()=>{
        fetchEditFiles();
    },[]);

    const fetchEditFiles = () => {
        http.get('youtube-link/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
          setYoutubeLink(removeEmpty(res.data.data));
        })
    }
    // console.log(getAppointments);
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }


    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        data = {...youtubeLink};
        data = removeEmpty(data)
  
        setLoader(true)
      http.post('/youtube-link/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/youtube-link");
          },500)
        })
      .catch((err)=> {
          setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
      setErrorObj({})
      let file = {...youtubeLink};
      file[e.target.name] = e.target.value
      setYoutubeLink(file);
    }
    // console.log(files);
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {youtubeLink == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit File System</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={youtubeLink.title}
                      placeholder="Enter name"
                    //   {...register("name")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.name? 
                  <span className="text-danger">
                    {getErrorObj.name}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Youtube URL</Form.Label>
                  <Form.Control as="textarea" rows={2}
                      type="text"
                      name="link"
                      defaultValue={youtubeLink?.link}
                      placeholder="Enter Youtube URL"
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.link? 
                  <span className="text-danger">
                    {getErrorObj.link}
                  </span> : null
                  }
              </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/youtube-link">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default YoutubeLinkEdit