// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Layout from '../components/layouts/layout';
// import loginCheck from '../helpers/loginCheck';

// const Dashboard = () => {

//     const navigate = useNavigate();

//     useEffect(()=> {
//         if(!loginCheck()){
//             console.log("this works")
//             navigate("/login");
//         }
//     });

//     return (
//         <Layout>
//             <div>
//                 <h1>dashboard page</h1>
//             </div>
//         </Layout>
//     );
// };

// export default Dashboard;

import React,{useState,useEffect } from 'react';
// import './booking.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../components/layouts/layout';
import loginCheck from '../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../helpers/ComponentGuard';
import { BsFillCursorFill } from 'react-icons/bs';

const Booking = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [bookings, setBookings] = useState([]);
  const [getDeleteBooking, setDeleteBooking] = useState(false);
  const [search, setSearch] = useState("");
  const [filterBookings, setFilterBookings] = useState([]);

  const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  
  const originalUrl = process.env.REACT_APP_BASE_URL
  let image = originalUrl.replace("/api", "");

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    const deleteBooking = (id) => 
    {
        http.get('/booking/delete/'+id,{
          headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           } 
        }).then((res)=>{
            setDeleteBooking(true);
            setTimeout(()=>{
                setDeleteBooking(false)}
            ,2000) 
                
        })
        handleClose();
    };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllBookings(getCurrentPage);
    },[getDeleteBooking]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/booking/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterBookings(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);

    console.log(filterBookings)

    // +++++++++++++++++++
    const fetchAllBookings = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/booking/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data)
        setFilterBookings(response.data.data.data)
		setBookings(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/booking/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data);
        
		setBookings(response.data.data.data);
        console.log("+++",response.data.data.data);
        setFilterBookings(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllBookings(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++

    // +++++++++++++++++++
    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }
    console.log(bookings)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable:true
        },
        {
            name: "Room Name",
            selector: row => (row.room_id)? row.room.name : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "From Date Time",
            selector: row => (row.from)?formateDate(row.from):<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "To Date Time",
            selector: row => (row.to)?formateDate(row.to):<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Notice",
            selector: row => (row.file_url)?
                <a href={image+row.file_url} target="_blank"><BsFillCursorFill/> </a>:
                <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        // {
        //     name: "Action",
        //     cell: (row) => 
        //     (
        //         <>
        //             <ComponentGuard needsPermission={['booking.details']}>
        //                 <Link to={"read/"+row.id}>
        //                     <Button className="action__btn" variant="info">
        //                         <GrView/>
        //                     </Button>
        //                 </Link>
        //             </ComponentGuard>

        //             <ComponentGuard needsPermission={['booking.update']}>
        //                 <Link to={"edit/"+row.id}>
        //                     <Button className="action__btn" variant="primary">
        //                         <AiTwotoneEdit/>
        //                     </Button>
        //                 </Link>
        //             </ComponentGuard>

        //             <ComponentGuard needsPermission={['booking.delete']}>
        //                 <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
        //                     <MdDelete/>
        //                 </Button>
        //             </ComponentGuard>
        //         </>
        //     ),            
        // }
    ]

    return (
        <>
        <Layout>

            {/* {     
                (getDeleteBooking) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body>Conference Room Booking Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            <ComponentGuard needsPermission={['booking.create']}>
                <Link to="create">
                    <Button className="create_btn btn btn-primary" variant="primary">Add Conference Room Booking</Button>
                </Link>
            </ComponentGuard> */}

            <DataTable 
                title="Conference Room Status"
                columns={columns} 
                data={filterBookings} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                // subHeaderComponent={
                //     <input 
                //     type="text" 
                //     placeholder="Search Here" 
                //     className="search_btn form-control"
                //     value={search}
                //     onChange={(e)=> setSearch(e.target.value)}
                //     />
                // }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/booking">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteBooking(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </Layout>
        </>
    );
};

export default Booking;