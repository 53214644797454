import React,{useState,useEffect } from 'react';
import './doctorVisit.css';
import { Link,useNavigate,useParams } from 'react-router-dom';
import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import http from "../../http";
import { Button, Form, Toast } from 'react-bootstrap';
import Cookies from 'universal-cookie';
const DoctorVisitCalender = () => {

    const [dateArr,setDateArr] =useState([])
    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    const {id} = useParams()
    const cookies = new Cookies();
    // http.get(`/visit/get/doctor?doctor_id=${val}`).then((response)=>{

    useEffect(()=>{
        fetchAllHolidays();
      },[]);
  
      const fetchAllHolidays = () => {
          let arr =[]
          http.get(`visit/get/doctor?doctor_id=${id}`, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            console.log(res.data.data)
              res.data.data.map((d)=>{
                arr.push({
                    "max_visitor":d.max_visitor,
                    "from":d.from.substring(0,10),
                    "from_time":d.from.substring(11,19),
                    "to_time":d.to.substring(11,19)
                })
              })
              setDateArr(arr)
            })
        }
        console.log(dateArr)
        

    function getTodoList(date) {
        const d = new Date(date).toISOString().split('T')[0];
        let todo = []
      
        const formateDate = (value) =>{
          // let tempDate = new Date(value).toUTCString().toString();
          var hours = value.substring(0, 2);
          var minutes = value.substring(3, 5);
          console.log(value,hours,minutes)
          // console.log(hours,minutes)
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
          console.log(strTime);
          return strTime
      }

        dateArr.forEach(data => {
            console.log(typeof data.from, data.from, typeof d, d, data.from === d)
            todo[data.from] = todo[data.from]?.length > 0 ? [...todo[data.from], {"max_visitor": data.max_visitor,"from_time": formateDate(data.from_time),"to_time": formateDate(data.to_time)}] : 
            [{"max_visitor": data.max_visitor,"from_time": formateDate(data.from_time),"to_time": formateDate(data.to_time)}]
        })
        if(typeof todo[d] === "undefined"){
            return [];
        }
        return todo[d];
      }

   function renderCell(date) {
    const list = getTodoList(date);
    // console.log(date,list);
    const displayList = list.filter((item, index) => index < 0);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li style={{listStyle: "none", textAlign: "start", wordBreak: "break-all"}}>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>
                    <Badge /> <b>{item.max_visitor}</b> ({item.from_time+" - "+item.to_time})
                  </p>
                ))}
              </Popover>
            }
          >
            <a><Badge /> {moreCount} Events</a>
          </Whisper>
        </li>
      );

      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li key={index} style={{listStyle: "none", textAlign: "start", wordBreak: "break-all"}}>
                    <Badge /> <b>{item.max_visitor}</b> ({item.from_time+" - "+item.to_time})
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

  return (
  
    <>
        <Layout>
        <Link to="/doctor-visit">
            <Button className="action__btn" variant="info">
                Back
            </Button>
        </Link>
            <Calendar bordered renderCell={renderCell} />
        </Layout>
    </>
    );
};


export default DoctorVisitCalender