import React,{useState,useEffect} from 'react'
import './doctor.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';

const DoctorRead = () => {
    const [doctors, setDoctors] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchSingleDoctors();
    },[]);
    
    const fetchSingleDoctors = () => {
        http.get('doctor/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setDoctors(res.data.data);
        })
    }
    console.log(doctors)

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {doctors == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Name:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.name)?doctors.name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Minute Per Patient:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.minute_per_patient)?doctors.minute_per_patient+" minutes": 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Education:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.education)?doctors.education : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Designation:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.designation)?doctors.designation : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Experience:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.experience)?doctors.experience : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Email:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.email)?doctors.email : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Phone:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.phone)?doctors.phone : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Department:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.department_id)?doctors.department.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Special Doctor:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.dr_speciality_id)?doctors.dr_speciality.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Patient Report:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.is_report_upload == 1)?
                            <Badge bg="success" text="white">Yes</Badge>:
                            <Badge bg="warning" text="dark">No</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Visit:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.visit)?doctors.visit :
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.lang_parent)?doctors.lang_parent.name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.image_url)?
                             <p>
                             {
                               (doctors.image_url) ? <a href={image+'/'+doctors.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(doctors.lang)?doctors.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Description:</label>
                    </div>
                     {doctors.description?
                     
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={doctors.description}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }

                </div>
            }
            </div>
        </div>

        <ComponentGuard needsPermission={['doctor.update']}>
            <Link to={"/doctor/edit/"+doctors.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default DoctorRead