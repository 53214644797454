import React from 'react'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// xl code
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr

// export function convertArrayOfObjectsToCSV(array) {
//     let result;
  
//     const columnDelimiter = ",";
//     const lineDelimiter = "\n";
//     const keys = Object.keys(array[0]);
  
//     result = "";
//     result += keys.join(columnDelimiter);
//     result += lineDelimiter;
  
//     array.forEach((item) => {
//       let ctr = 0;
//       keys.forEach((key) => {
//         if (ctr > 0) result += columnDelimiter;
  
//         result += item[key];
//         // eslint-disable-next-line no-plusplus
//         ctr++;
//       });
//       result += lineDelimiter;
//     });
  
//     return result;
//   }
  
// // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
// export default function downloadCSV(array) {
//     const link = document.createElement("a");
//     let xls = convertArrayOfObjectsToCSV(array);
//     if (xls == null) return;
  
//     const filename = "export.xls";
  
//     if (!xls.match(/^data:text\/xls/i)) {
//       xls = `data:text/xls;charset=utf-8,${xls}`;
//     }
  
//     link.setAttribute("href", encodeURI(xls));
//     link.setAttribute("download", filename);
//     link.click();
//   }


// export default function DataTableXlDownload (apiData, fileName ) {
//     const fileType =
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//     const fileExtension = ".xlsx";
  
    export default function  DataTableXlDownload (apiData, fileName) {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

// }


  
//   // eslint-disable-next-line react/prop-types
//   const Export = ({ onExport }) => (
//     <button onClick={(e) => downloadCSV(filterDepartments)}>Export</button>
//   );

  
//   const actionsMemo = React.useMemo(
//     () => <Export onExport={() => downloadCSV(filterDepartments)} />,
//     []
//   );


// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// xl code
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

