import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './publication.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker,TagInput } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import {publicationType} from '../enum/enum'

const PublicationEdit = () => {
    const {id} = useParams()
    const [publications, setPublications] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/publications/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }
    
    
    useEffect(()=>{
      fetchEditPublicationss();
    },[]);
    
    const fetchEditPublicationss = () => {
      http.get('publication/get/'+id, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then(res=>{
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>",Object.values(res.data.data.meta));
        setPublications(removeEmpty(res.data.data));
      })
    }
    console.log(publications)
    
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      const image_url = data.image_url[0];
      const file_url = data.file_url[0];
      delete publications.image_url;
      delete publications.file_url;
      delete publications.slug;
      delete publications.lang_parent;
      // console.log(JSON.stringify(publications.meta))
      
      data = {...data, ...publications};
      const meta = data.meta
      data.user_id = cookies.get('userAuth').id
    
      // console.log(data);
      // data = removeEmpty(data)
      // console.log(meta)
      // return
      if(meta){
        data.meta = meta.toString()
      }
      else{
        data.meta = null
      }  
      data.image_url = image_url;
      data.file_url = file_url;
      console.log(data);
      setLoader(true)
      http.post('/publication/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        // setSuccess(true);
        localStorage.setItem("success", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/publication");
          },1000)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let publication = {...publications};
    publication[e.target.name] = e.target.value
    setPublications(publication);
}

return (
    <>
    <Layout>

        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }
      {publications == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Publication</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={publications.title}
                      placeholder="Enter title"
                      // {...register("title")}
                      onChange={(e) => handleChange(e)}

                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Sub Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="sub_title"
                      defaultValue={publications.sub_title}
                      placeholder="Enter Sub title"
                      onChange={(e) => handleChange(e)}
                      // {...register("sub_title")}
                  />
                  {getErrorObj.sub_title? 
                  <span className="text-danger">
                    {getErrorObj.sub_title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Thumbnail</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (publications.image_url) ? <a href={image+'/'+publications.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Attachment</Form.Label>
                  <Form.Control
                      type="file"
                      name="file_url"
                      placeholder="Enter Image file"
                      {...register("file_url")}
                  />
                  <p>
                    {
                      (publications.file_url) ? <a href={image+'/'+publications.file_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.file_url? 
                  <span className="text-danger">
                    {getErrorObj.file_url}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Type</Form.Label>
                  <SelectPicker 
                          name="type"
                          defaultValue={publications.type}
                          data={Object.keys(publicationType).map((item) => ({label: publicationType[item], value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setPublications({...publications, "type": value})}
                  />
                  {getErrorObj.type? 
                  <span className="text-danger">
                    {getErrorObj.type}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          defaultValue={publications.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setPublications({...publications, "lang_parent_id": value})}

                      />
                      {getErrorObj.lang_parent_id? 
                    <span className="text-danger">
                      {getErrorObj.lang_parent_id}
                    </span> : null
                    }
                    {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          defaultValue={publications.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setPublications({...publications, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>
                  
                 {/* { console.log(['Harry', 'John', 'Clark', 'Peter', 'Rohn', 'Alice'])}
                 { console.log(Object.values(publications.meta))} */}

                <Form.Group>
                    <Form.Label className="form_label">Meta Tag</Form.Label>
                    <TagInput 
                          trigger={['Enter', 'Space', 'Comma']}      
                          defaultValue={(publications.meta)?Object.values(publications.meta):[]}  
                          style={{ width: "100%" }} 
                          onChange={(value) => setPublications({...publications, "meta": value})}
                          // onChange={(value) => console.log(value)}
                          onClean={()=> setPublications({...publications, "meta": []})}
                      />

                    {getErrorObj.meta? 
                    <span className="text-danger">
                      {getErrorObj.meta}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/publication">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default PublicationEdit