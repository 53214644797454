import React ,{useState,useEffect} from 'react'
import './innerpage.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang,insidePage} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker,DateRangePicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin';

const InnerPageCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [innerPages,setInnerPages] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getArr, setArr] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        
        http.get('/resource/admin/all', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            let arr = []
            res.data.data.data.map(d => arr.push(d.reference_path))
            let filterData = getDifference(Object.keys(insidePage), arr)
            setArr(filterData)
        })
    },[])
    
    function getDifference(a, b) {
        return a.filter(element => {
          return !b.includes(element);
        });
      }
      console.log(getArr)

    useEffect(()=>{
        fetchAllParents();
        // fetchAllDepartments();
    },[]);

    const fetchAllParents = () => {
        http.get('/resource/parent', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setParents(res.data.data);
        })
    }

    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];        
        delete innerPages.image_url;
        data = {...data, ...innerPages};
        data = removeEmpty(data)
        
        data.image_url = image_url;
        data.user_id = cookies.get('userAuth').id

        console.log(data)
        setLoader(true)

        http.post('/resource/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            localStorage.setItem("success", true);
            setTimeout(()=>{
              navigate("/inner-page");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        console.log(e);
        let innerPage = {...innerPages};
        innerPage[e.target.name] = e.target.value
        setInnerPages(innerPage);
    }


  return (
    <>
    <Layout>
        {getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Inner Page </h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Title Extra</Form.Label>
                    <Form.Control
                        type="text"
                        name="title_extra"
                        placeholder="Enter Title Extra"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.title_extra? 
                    <span className="text-danger">
                      {getErrorObj.title_extra}
                    </span> : null
                    }
                </Form.Group>
                

                <Form.Group>
                    <Form.Label className="form_label">Path</Form.Label>
                    <SelectPicker 
                          name="type"
                        //   data={Object.keys(insidePage).map((item) => ({label: insidePage[item], value: item }))}                           
                          data={getArr.map((item) => ({label: insidePage[item], value: item }))}                           
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setType(value))}
                        onChange={(value) => setInnerPages({...innerPages, "reference_path": value})}
                      />
                    {getErrorObj.reference_path? 
                    <span className="text-danger">
                      {getErrorObj.reference_path}
                    </span> : null
                    }
                </Form.Group>
            

                <Form.Group>
                    <Form.Label className="form_label">Image url</Form.Label>
                    <Form.Control
                        type="file"
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url")}
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setInnerPages({...innerPages, "lang_parent_id": value})}
                      />
                       {getErrorObj.lang_parent_id? 
                        <span className="text-danger">
                        {getErrorObj.lang_parent_id}
                        </span> : null
                        }
                        {getLangError? 
                        <span className="text-danger">
                          {getLangError}
                        </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          {...register("lang")} 
                          onChange={(value) => setInnerPages({...innerPages, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                        extraPlugins: [uploadPlugin]
                        }}    
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setInnerPages({...innerPages, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/inner-page">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default InnerPageCreate