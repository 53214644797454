import React ,{useState,useEffect} from 'react'
import './specialDoctor.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';

const SpecialDoctorCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [specialDoctors, setSpecialDoctors] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/doctor/speciality/parent', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setParents(res.data.data);
        })
    }

    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
      }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        data = {...data, ...specialDoctors};
        data.user_id = cookies.get('userAuth').id
        data = removeEmpty(data)
        setLoader(true)
        http.post('/doctor/speciality/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/special-doctor");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        let specialDoctor = {...specialDoctors};
        specialDoctor[e.target.name] = e.target.value
        setSpecialDoctors(specialDoctor);
    }
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Special Doctor</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>
            
                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    {/* <Form.Select name="lang_parent_id" {...register("lang_parent_id")} aria-label="Default select example">
                        <option value="">Select Parent</option>
                        {
                                parents.data?.map(key => 
                                    (<option value={key.id}>{key.title}</option>)                            
                                )
                        }
                    </Form.Select> */}
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setSpecialDoctors({...specialDoctors, "lang_parent_id": value})}
                          />
                        {getErrorObj.lang_parent_id? 
                            <span className="text-danger">
                            {getErrorObj.lang_parent_id}
                            </span> : null
                        }
                        {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    {/* <Form.Select name="lang" {...register("lang")} aria-label="Default select example">
                        <option value="">Select Language</option>
                        {
                            Object.keys(lang).map(key => 
                                <option value={key}>{lang[key]}</option>
                            )
                        }
                    </Form.Select> */}
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                        //   {...register("lang")} 
                          onChange={(value) => setSpecialDoctors({...specialDoctors, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>


                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/special-doctor">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}
export default SpecialDoctorCreate;
