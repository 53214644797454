import React,{useState,useEffect} from 'react'
import './innerpage.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import { SelectPicker, DatePicker} from 'rsuite';
import {lang,insidePage} from '../enum/enum'
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';
const InnerPageRead = () => {
    const [innerPages,setInnerPages] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    const cookies = new Cookies()
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(innerPages)
    useEffect(()=>{
        fetchSingleJobPost();
    },[]);

    const fetchSingleJobPost = () => {
        http.get('resource/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setInnerPages(res.data.data);
        })
    }

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {innerPages == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(innerPages.title)?innerPages.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Title Extra:</label>
                    </div>
                    <div className="col-sm-6">
                        {(innerPages.title_extra)?innerPages.title_extra : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Path:</label>
                    </div>
                    <div className="col-sm-6">
                        {(innerPages.reference_path)?insidePage[innerPages.reference_path] : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(innerPages.image_url)?
                             <p>
                             {
                               (innerPages.image_url) ? <a href={image+'/'+innerPages.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   
                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(innerPages.lang_parent)?innerPages.lang_parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(innerPages.lang)?innerPages.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Description:</label>
                    </div>
                     {innerPages.description?
                     
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={innerPages.description}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }

                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['resource.update']}>
            <Link to={"/inner-page/edit/"+innerPages.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default InnerPageRead