import React, { useState, useEffect } from "react";
import { Button, Modal, Toast, Badge, Form } from "react-bootstrap";
import { SelectPicker, DatePicker, CheckPicker } from "rsuite";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import http from "../../http";
import _ from "lodash";
import moment from "moment/moment";
import { day } from "../enum/enum";
import Layout from "../../components/layouts/layout";
import { Divider, Table } from "antd";
import { FaExclamationCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";


const DeleteMultiple = () => {
  const { register, handleSubmit, reset, setValue, getValues } = useForm();
  const [getDoc, setDoc] = useState([]);
  const [getVisit, setVisit] = useState([]);
  const [getVisitIdArr, setVisitIdArr] = useState([]);
  const [getErrorObj, setErrorObj] = useState({});
  const [getLoader, setLoader] = useState(false);
  const [getError, setError] = useState("");
  const [keys, setKeys] = useState([]);
  const [selectedValueDoctor, setSelectedValueDoctor] = useState(null);
  const [show, setShow] = useState(false);
  const [getSuccess, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cookies = new Cookies();
  const navigate = useNavigate();

  console.log(getVisitIdArr);

  const getSearch = (val) => {
    if (val.length > 0) {
      http
        .get(`/doctor/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc(response.data.data.data);
        });
    } else if (getVisit.doctor_id) {
      http
        .get(`/doctor/get/${getVisit.doctor_id}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc([response.data.data]);
        });
    } else {
      setDoc([]);
    }
  };

  const formateDate = (value) => {
    let tempDate = new Date(value).toUTCString().toString();
    let date = tempDate.substring(0, 16);
    var hours = tempDate.substring(17, 19);
    var minutes = tempDate.substring(20, 22);
    // console.log(hours,minutes)
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    // console.log(strTime);
    return date + " " + strTime;
  };

  const onSubmit = (data, e) => {
    setErrorObj({});
    data = { ...data, ...getVisit };
    console.log(data);

    // return ; //visit_id = 1,2,3,4,5,6,....
    setLoader(true);
    http
      .get("/visit/getVisit/" + data.doctor_id, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log(res?.data?.data);

          const visitedFields = res.data?.data.map((singleData) => {
            return {
              key: singleData.id,
              id: singleData.id,
              name: singleData?.doctor?.name,
              from: formateDate(singleData?.from),
              to: formateDate(singleData?.to),
              appointments_count: singleData?.appointments_count,
            };
          });
          setVisitIdArr(visitedFields);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        // console.log(Object.keys(err.response.data.data))
        // console.log(err.response.data.message);
        let errs = err.response.data.data;
        let keys = Object.keys(errs);
        console.log(keys);
        let errors = {};
        keys.map((key) => (errors[key] = errs[key][0]));
        console.info(errors);
        setError(err.response.data);

        setErrorObj(errors);
      });
  };

  // ant table

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Appoinment Count",
      dataIndex: "appointments_count",
    },
    {
      title: "From Date",
      dataIndex: "from",
    },
    {
      title: "To Date",
      dataIndex: "to",
    },
  ];

  const rowSelection = {
    selectedRowKeys, // Store selected row keys in state
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.appointments_count != 0,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };
   console.log(selectedRowKeys);
   console.log(selectedRowKeys?.length);
  const onSubmitRowData = (e) => {

    console.log("clicked");
    // Convert the selected row keys to a comma-separated string
   
    
    handleShow(true);

  };
  const handleDeleteRowApi =()=>{
    console.log("delete api called here")
    const selectedRowKeysString = selectedRowKeys.join(", ");
    let data = {"visitIds":selectedRowKeysString}
    console.log(data)
     
    setLoader(true);
    http
      .post("/visit/delete-multiple", data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        console.log(res);
        setSuccess(true);
        localStorage.setItem("successDelete", true);
        // setLoader(false);
        setTimeout(() => {
          navigate("/doctor-visit");
        }, 1000);
      })
  }
  return (
    <>
      <Layout>
        <>
          <h6 className="mb-4">Delete Multiple Row</h6>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-lg-flex d-md-flex align-items-lg-center align-items-md-center">
              <Form.Group className="col-lg-3 col-md-4 col-sm-12 pb-2">
                <SelectPicker
                  name="doctor_id"
                  value={selectedValueDoctor}
                  data={getDoc?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  style={{ width: "100%" }}
                  onSearch={(val) => getSearch(val)}
                  onChange={(value) => {
                    setSelectedValueDoctor(value);
                    setVisit({ ...getVisit, doctor_id: value });
                  }}
                />
              </Form.Group>

              <Button
                className="btn btn-primary mt-lg-0 ml-2 mb-2 mt-2"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>

          {/* ant table form */}
          <div className="d-flex justify-content-end pe-5">
            <button
              class="btn btn-danger"
              onClick={() => onSubmitRowData()}
              type="button"
              disabled={selectedRowKeys.length == 0 ? true : false}
            >
              Delete
            </button>
          </div>
          <div>
            <Divider />
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={getVisitIdArr}
              pagination={false}
            />
          </div>
        </>
      </Layout>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "50px", margin: "auto" }}>
            <FaExclamationCircle />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontSize: "25px" }}>
          Are you sure you want to delete this row?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteRowApi}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteMultiple;
