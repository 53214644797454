import React,{useState,useEffect } from 'react';
import './news.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';
import { FaFileImage } from 'react-icons/fa';

const News = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [newses, setNewses] = useState([]);
  const [getDeleteNews, setDeleteNews] = useState(false);
  const [search, setSearch] = useState("");
  const [filterNewses, setFilterNewses] = useState([]);

  const [loading, setLoading] = useState(false);
  const [imageShow, setImageShow] = useState("");
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
    const [getAppData, setAppData] = useState([]);
    const [fileShow, setFileShow] = useState(false);
    const [fileDeleteShow, setFileDeleteShow] = useState(false);
    const [fileDeleteId, setFileDeleteId] = useState();

  const cookies = new Cookies();
  
  const image = process.env.REACT_APP_IMAGE_BASE_URL
  const updatedUrl = image.replace('/storage', '');


    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    const handleClose = () => {
        setShow(false);
        setImageShow('');
    }
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    const deleteNews = (id) => 
    {
        http.get('/news/delete/'+id,{
          headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           } 
        }).then((res)=>{
            setDeleteNews(true);
            setTimeout(()=>{
                setDeleteNews(false)}
            ,2000) 
                
        })
        handleClose();
    };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllNewses(getCurrentPage);
    },[getDeleteNews]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/news/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterNewses(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);

    console.log(filterNewses)

    // +++++++++++++++++++
    const fetchAllNewses = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/news/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data)
        setFilterNewses(response.data.data.data)
		setNewses(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/news/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          });
        console.log(response.data);
        
		setNewses(response.data.data.data);
        console.log("+++",response.data.data.data);
        setFilterNewses(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllNewses(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++
    
    function handleImageShow(img){
        setImageShow(img)
    }
    
    const handleCloseFileShow = () => setFileShow(false);
    const handleShowFileShow = (value) =>{
        setAppData(value)
        setFileShow(true);
    }

    const handleNewsSliderFileClose = () => setFileDeleteShow(false)
    const handleFileDeleteShow = (fileId) => {
        setFileDeleteShow(true)
        setFileDeleteId(fileId)
    }

    const deleteNewsSliderFile = (fileId) =>{
        http.get('/news/deleteMediaIdData/'+fileId,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((res)=>{
                setFileDeleteShow(false)   
                setFileShow(false)  
                setDeleteNews(true);
            setTimeout(()=>{
                setDeleteNews(false)}
            ,2000) 
          })
          handleClose();
    }

    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable:true
        },
        {
            name: "Sub Title",
            selector: row => (row.sub_title)? row.sub_title : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Department",
            selector: row => (row.department_id) ? row.department.title:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Language",
            selector: row => row.lang,
            sortable:true
        },
        {
            name: "Language Parent",
            selector: row => (row.lang_parent)?row.lang_parent.title:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Image",
            selector: row => (row.image_url) ? <img onClick={()=>handleImageShow(image+'/'+row.image_url)} width={50} height={50} src={image+'/'+row.image_url} /> 
            : 
            <Badge bg="warning" text="dark">N/A</Badge>,
        },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                    {
                        row?.files.length > 0 &&
                        <Button className="action__btn" onClick={() =>handleShowFileShow(row)} variant="success">
                            <FaFileImage />
                        </Button>
                    }
                    <ComponentGuard needsPermission={['news.details']}>
                        <Link to={"read/"+row.id}>
                            <Button className="action__btn" variant="info">
                                <GrView/>
                            </Button>
                        </Link>
                    </ComponentGuard>

                    <ComponentGuard needsPermission={['news.update']}>
                        <Link to={"edit/"+row.id}>
                            <Button className="action__btn" variant="primary">
                                <AiTwotoneEdit/>
                            </Button>
                        </Link>
                    </ComponentGuard>

                    <ComponentGuard needsPermission={['news.delete']}>
                        <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                            <MdDelete/>
                        </Button>
                    </ComponentGuard>
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>

            {     
                (getDeleteNews) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body >News Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            <ComponentGuard needsPermission={['news.create']}>
                <Link to="create">
                    <Button className="create_btn btn btn-primary" variant="primary">Add News</Button>
                </Link>
            </ComponentGuard>

            <DataTable 
                title="News List"
                columns={columns} 
                data={filterNewses} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input 
                    type="text" 
                    placeholder="Search Here" 
                    className="search_btn form-control"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}
                    />
                }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/news">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteNews(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            <Modal show={imageShow} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img src={imageShow} width="100%" height="400px" style={{objectFit:"cover"}}/>
                </Modal.Body>
            </Modal>

            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={fileShow} onHide={handleCloseFileShow}>
                <Modal.Header closeButton>
                    <Modal.Title>{getAppData?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="file-list">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">File</th>
                                <th scope="col">Size</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getAppData?.files?.map((d,index)=>(
                                <tr>
                                    <th scope="row">{index+1}</th>
                                    <td>
                                        <img src={updatedUrl+d?.url} height={50} width={70}/>
                                    </td>
                                    <td>{(d.sizeInBytes/1024).toFixed(2)} KB</td>
                                    <td>
                                    <Button className="action__btn" onClick={() =>handleFileDeleteShow(d?.id)} variant="danger">
                                        <MdDelete/>
                                    </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="/appointment">
                        <Button className="action__btn" variant="secondary" onClick={handleCloseFileShow}>
                            Close
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            <Modal show={fileDeleteShow} onHide={handleNewsSliderFileClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Link to="/news">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteNewsSliderFile(fileDeleteId)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

        </Layout>
        </>
    );
};

export default News;