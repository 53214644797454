import React ,{useState,useEffect} from 'react'
import './package.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const PackageCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [packages,setPackages] = useState('');
    const [val,setval] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [departments, setDepartments] = useState([]);
    const [services, setServices] = useState([]);
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllParents();
    },[]);

    console.log(services)
    const fetchAllParents = () => {
        http.get('/service/parent', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setParents(res.data.data);
        })
    }
    const getSearch = (val) =>{
        console.log(val,packages.service_id);
        if(val.length > 0){
            http.get(`/service/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setServices(response.data.data.data);
            })
        } else if(packages.service_id){
            http.get(`/service/get/${packages.service_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setServices([response.data.data]);
            })
        } else {
            setServices([]);
        }
    }

    const getDeptSearch = (val) =>{
        if(val.length > 0){
            http.get(`/department/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDepartments(response.data.data.data);
            })
        }else if(packages.department_id){
            http.get(`/department/get/${packages.department_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDepartments([response.data.data]);
            })
        } else {
            setDepartments([]);
        }
    }

    function handleKeyPress(e) {
        // console.log(e.code)
        if (['Minus', 'NumpadSubtract'].includes(e.code)) {
            console.log(e.code);
            e.target.value = e.target.value.slice(0, -1)
        }
        else if(/^0/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^0/, "")
        }
        let intpart = e.target.value.split(".")[0];
        let decimal = e.target.value.split(".")[1].slice(0, 4);
        decimal = decimal.length > 0 ? "."+decimal : "";
        e.target.value = intpart + decimal;
        }
            
    function removeEmpty(obj) {
        return Object.fromEntries(
            Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];        
        delete packages.image_url;
        data = {...data, ...packages};
        data.user_id = cookies.get('userAuth').id
        
        data = removeEmpty(data)
        
        data.image_url = image_url;

        data.user_id = cookies.get('userAuth').id
        setLoader(true)
        http.post('/service/package/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/package");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        let packag = {...packages};
        packag[e.target.name] = e.target.value
        setPackages(packag);
    }

  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Package</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.name? 
                    <span className="text-danger">
                      {getErrorObj.name}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Sub Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="sub_title"
                        placeholder="Enter Sub Title"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.sub_title? 
                    <span className="text-danger">
                      {getErrorObj.sub_title}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          name="department_id" 
                          data={departments?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setPackages({...packages, "department_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Service</Form.Label>
                    <SelectPicker 
                          name="service_id" 
                          data={services?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getSearch(val)}
                          onChange={(value) => setPackages({...packages, "service_id": value})}
                      />
                    {getErrorObj.service_id? 
                    <span className="text-danger">
                      {getErrorObj.service_id}
                    </span> : null
                    }
                </Form.Group>
            
                <Form.Group>
                    <Form.Label className="form_label">Image url</Form.Label>
                    <Form.Control
                        type="file"
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url")}
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                </Form.Group>
                
                <Form.Group>
                    <Form.Label className="form_label">Price</Form.Label>
                    <Form.Control
                        type="number"
                        name="price"
                        step="0.01"
                        placeholder="Enter Price"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("visit")}
                        onChange={(e) => handleChange(e)}
                        onWheel={ event => event.currentTarget.blur() }
                    />
                    {getErrorObj.price? 
                    <span className="text-danger">
                      {getErrorObj.price}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setPackages({...packages, "lang_parent_id": value})}
                      />
                       {getErrorObj.lang_parent_id? 
                    <span className="text-danger">
                      {getErrorObj.lang_parent_id}
                    </span> : null
                    }
                    {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          {...register("lang")} 
                          onChange={(value) => setPackages({...packages, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setPackages({...packages, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/package">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default PackageCreate