import React,{useState,useEffect } from 'react';
import './activitylog.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';

const ActivityLog = () => {

    const [show, setShow] = useState(false);
    const [id, setId] = useState('');
    const [activitys, setActivitys] = useState([]);
    const [getDeleteActivity, setDeleteActivity] = useState(false);
    const [search, setSearch] = useState("");
    const [filterActivitys, setFilterActivitys] = useState([]);

    const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
    const cookies = new Cookies();

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });



    // const image = process.env.REACT_APP_IMAGE_BASE_URL

    // const handleClose = () => setShow(false);
    // const handleShow = (id) => {
    //     setShow(true);
    //     setId(id)  
    // };

    // const deleteActivity = (id) => 
    // {
    //     http.get('/activity/delete/'+id,{
    //         headers:{
    //           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //          } 
    //       }).then((res)=>{
    //     setDeleteActivity(true);

    //         setTimeout(()=>{
    //             setDeleteActivity(false)}
    //         ,2000) 
                
    //     })
    //     handleClose();
    // };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllActivitys(getCurrentPage);
    },[]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/activity/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterActivitys(response.data.data.data);
        })
        
        // })
        // fetchAllActivitys2()
    },[search]);

    // +++++++++++++++++++
    const fetchAllActivitys = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/activity/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then((response)=>{
            console.log(response.data)
            setFilterActivitys(response.data.data.data)
            setActivitys(response.data.data.data);
            setTotalRows(response.data.data.total);
            setLoading(false);
        });
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/activity/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then((response)=>{
            console.log(response.data);
        
            setActivitys(response.data.data.data);
            console.log("+++",response.data.data.data);
            setFilterActivitys(response.data.data.data)
            setPerPage(perPage);
            setLoading(false);
        });
    
	};

	useEffect(() => {
		fetchAllActivitys(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++


    console.log(activitys)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Type",
            selector: row => (row.log_name)?row.log_name : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true,
            width: "100px"

        },
        {
            name: "Description",
            selector: row => (row.description)?row.description : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true,
            width: "280px"
        },
        {
            name: "IP",
            selector: row => (row.ip)?row.ip : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true,
            width: "100px"
        },
        {
            name: "user_agent",
            selector: row => (row.user_agent)?row.user_agent:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true,
        },
        // {
        //     name: "Action",
        //     cell: (row) => 
        //     (
        //         <>
        //             <Link to={"read/"+row.id}>
        //                 <Button className="action__btn" variant="info">
        //                     <GrView/>
        //                 </Button>
        //             </Link>
        //             <Link to={"edit/"+row.id}>
        //                 <Button className="action__btn" variant="primary">
        //                     <AiTwotoneEdit/>
        //                 </Button>
        //             </Link>
        //             <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
        //                 <MdDelete/>
        //             </Button>
        //         </>
        //     ),            
        // }
    ]

    return (
        <>
        <Layout>

            {/* {     
                (getDeleteActivity) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body >Activity Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                } */}

            <DataTable 
                title="Activity Log List"
                columns={columns} 
                data={filterActivitys} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input 
                    type="text" 
                    placeholder="Search Here" 
                    className="search_btn form-control"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}
                    />
                }
                subHeaderAlign="right"
            />
            

            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Link to="/menu">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteActivity(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal> */}
        </Layout>
        </>
    );
};

export default ActivityLog;