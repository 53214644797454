import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './booking.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker,DateRangePicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin';

const BookingEdit = () => {
    const {id} = useParams()
    const [bookings, setBookings] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [getFrom,setFrom] = useState('')
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const originalUrl = process.env.REACT_APP_BASE_URL
    let image = originalUrl.replace("/api", "");
    
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // useEffect(()=>{
    //   fetchAllParents();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/event/parent', {
    //       headers:{
    //        "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //       }  
    //   }).then(res=>{
    //         setParents(res.data.data);
    //     })
    // }

  //   useEffect(()=>{
  //     if(bookings.room_id)
  //        getDeptSearch()
  //   },[bookings]);
  
  
  // const getDeptSearch = (val = "") =>{
  //     let val1 = val.length > 0 ? val : '*';
  //     if(val.length > 0) {
  //       http.get(`/room/search/${val}`, {
  //         headers:{
  //          "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
  //         }  
  //     }).then((response)=>{
  //         setRooms(response.data.data);
  //       })
  //     }else if(bookings.room_id){
  //       http.get(`/room/get/${bookings.room_id}`, {
  //         headers:{
  //          "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
  //         }  
  //     }).then((response)=>{
  //         setRooms({'data': [response.data.data]});
  //       })
  //     } else {
  //       http.get(`/room/search/${val1}`, {
  //         headers:{
  //          "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
  //         }  
  //     }).then((response)=>{
  //         setRooms({'data': []});
  //       })
  //     }
  // }

  useEffect(()=>{
    fetchAllRoom();
    // fetchAllDepartments();
},[]);

const fetchAllRoom = () => {
    http.get('/room/getRoomName', {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then(res=>{
       setRooms(res.data.data)
    })
}


    useEffect(()=>{
      fetchEditBookings();
    },[]);

    const fetchEditBookings = () => {
        http.get('booking/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
          if(res.data.data.from){
            let fromDate = res.data.data.from
            let tempDateFrom = new Date(fromDate).toUTCString().toString();
            let dateFrom =  tempDateFrom.substring(0, 16)
            let timeFrom =  tempDateFrom.substring(17, 26) 
            res.data.data.from = formateDate(dateFrom+" "+timeFrom+" "+"GMT+0600 (Bangladesh Standard Time)")
            // console.log(res.data.data.from)
          }
          if(res.data.data.to){
            let toDate = res.data.data.to
            let tempDateTo = new Date(toDate).toUTCString().toString();
            let dateTo =  tempDateTo.substring(0, 16)
            let timeTo =  tempDateTo.substring(17, 26) 
            res.data.data.to = formateDate(dateTo+" "+timeTo+" "+"GMT+0600 (Bangladesh Standard Time)")
          }
          // console.table(res.data.data)
          setBookings(removeEmpty(res.data.data));
        })
    }
    
    console.log(bookings.room_id)
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const file_url = data.file_url[0];

        // const image_url = data.image_url[0];
        // delete events.image_url;
        // delete events.department
        delete bookings.room
        delete bookings.file_url;

        data = {...data, ...bookings};
        data.user_id = cookies.get('userAuth').id

        // data = removeEmpty(data)
        data.file_url = file_url;

      setLoader(true)
      
      http.post('/booking/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/booking");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let booking = {...bookings};
    booking[e.target.name] = e.target.value
    setBookings(booking);
}

const formateDate = (val) =>{
  console.log(val)
  let tempDate = new Date(val).toUTCString().toString();
  let fetchDate = tempDate.substring(5, 7);

  let monthConvert = 
  tempDate.substring(8, 11)=='Jan' ? "01" :
  tempDate.substring(8, 11)=='Feb' ? "02" :
  tempDate.substring(8, 11)=='Mar' ? "03" :
  tempDate.substring(8, 11)=='Apr' ? "04" :
  tempDate.substring(8, 11)=='May' ? "05" :
  tempDate.substring(8, 11)=='Jun' ? "06" :
  tempDate.substring(8, 11)=='Jul' ? "07" :
  tempDate.substring(8, 11)=='Aug' ? "08" :
  tempDate.substring(8, 11)=='Sep' ? "09" :
  tempDate.substring(8, 11)=='Oct' ? "10" :
  tempDate.substring(8, 11)=='Nov' ? "11" : 
  tempDate.substring(8, 11)=='Dec' ? "12" : null
  // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
  let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
  // console.log(date)

  let time = new Date(val).toLocaleTimeString('en-US',{
      hour12: false,
    });


  var combineDate = date +" "+ time;
  console.log(combineDate);
  return combineDate;

}


  return (
    <>
    <Layout>

    { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {bookings == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Conference Room Booking</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={bookings.title}
                      placeholder="Enter title"
                      // {...register("title")}
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>
                
              <Form.Group>
                    <Form.Label className="form_label">Room Name</Form.Label>
                    <SelectPicker 
                          defaultValue={bookings.room_id}
                          data={rooms?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          // onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setBookings({...bookings, "room_id": value})}
                      />
                    {getErrorObj.room_id? 
                    <span className="text-danger">
                      {getErrorObj.room_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">From Date</Form.Label>
                    <DatePicker
                        // defaultValue={(events.from) ? new Date(events.from) : ""}
                        defaultValue={(bookings.from) ? new Date(bookings.from) : ""}
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        oneTap
                        showWeekNumbers
                        style={{ width: "100%" }}
                        disabledDate={beforeToday()}
                        onChange={(value) => setBookings({...bookings, "from": formateDate(value)})}
                    />
                    {getErrorObj.from? 
                    <span className="text-danger">
                      {getErrorObj.from}
                    </span> : null
                    }
                    {getLangError? 
                    <span className="text-danger">
                      Date and Time Already Booked
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">To Date</Form.Label>
                    <DatePicker
                        // defaultValue={(events.to) ? new Date(events.to) : ""}
                        defaultValue={(bookings.to) ? new Date(bookings.to) : ""}
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        showWeekNumbers
                        oneTap
                        style={{ width: "100%" }}
                        disabledDate={beforeToday()}
                        // disabledDate={['2022-10-01','2022-10-02']}
                        onChange={(value) => setBookings({...bookings, "to": formateDate(value)})}
                    />
                    {getErrorObj.to? 
                    <span className="text-danger">
                      {getErrorObj.to}
                    </span> : null
                    }
                    {getLangError? 
                    <span className="text-danger">
                      Date and Time Already Booked
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        data={bookings.description}
                        editor={ FullEditor }
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setBookings({...bookings, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>


              <Form.Group>
                <Form.Label className="form_label">Notice</Form.Label>
                <Form.Control
                    type="file"
                    name="file_url"
                    placeholder="Enter File"
                    {...register("file_url")}
                />
                <p>
                  {
                    (bookings.file_url) ? <a href={image+bookings.file_url} target="_blank"><BsFillCursorFill/> </a> : ''
                  }
                </p>
                {getErrorObj.file_url? 
                <span className="text-danger">
                  {getErrorObj.file_url}
                </span> : null
                }
              </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/booking">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default BookingEdit