import React, { useState, useEffect } from "react";
import nav from "./appointment.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from "react-bootstrap";
import http from "../../http";
import { type, subType, level, order, lang } from "../enum/enum";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import Cookies from "universal-cookie";
import {
  SelectPicker,
  Radio,
  RadioGroup,
  DatePicker,
  DateRangePicker,
} from "rsuite";
import _ from "lodash";
import moment from "moment/moment";
// import { Radio, RadioGroup } from 'rsuite';

const AppointmentCreate = () => {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");
  const [appointments, setAppointments] = useState("");
  const [getSuccess, setSuccess] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [getError, setError] = useState("");
  const [getErrorObj, setErrorObj] = useState({});
  const [parents, setParents] = useState([]);
  const [getDoc, setDoc] = useState([]);
  const [getPhone, setPhone] = useState([]);
  const [getVisit, setVisit] = useState([]);
  const [getTime, setTime] = useState([]);
  const [getArr, setArr] = useState([]);
  const [getVisitId, setVisitId] = useState();
  const [getLoader, setLoader] = useState(false);
  
  const [imageFormValues,setImageFormValues] = useState('');

  const cookies = new Cookies();
  const {
    allowedMaxDays,
    allowedDays,
    allowedRange,
    beforeToday,
    afterToday,
    combine,
  } = DateRangePicker;

  const navigate = useNavigate();
  useEffect(() => {
    if (!loginCheck()) {
      console.log("this works");
      navigate("/login");
    }
  });


  const getSearch = (val) => {
    if (val.length > 0) {
      http
        .get(`/doctor/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc(response.data.data.data);
        });
    } else if (appointments.doctor_id) {
      http
        .get(`/doctor/get/${appointments.doctor_id}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc([response.data.data]);
        });
    } else {
      setDoc([]);
    }
  };

  const getPhoneSearch = (val) => {
    if (val.length > 0) {
      http
        .get(`/admin/user/phone/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setPhone(response.data.data.data);
        });
    } else if (appointments.phone) {
      http
        .get(`/admin/user/phone/get/${appointments.phone}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setPhone([response.data.data]);
        });
    } else {
      setPhone([]);
    }
  };

  useEffect(() => {
    if (appointments.doctor_id != null) {
      fetchDoctorVisit(appointments.doctor_id);
    }
  }, [appointments.doctor_id]);

  const fetchDoctorVisit = (val) => {
    http
      .get(`/visit/get/doctor?doctor_id=${val}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((response) => {
        setVisit(response.data.data);
      });
  };

  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null && v != "")
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  const onSubmit = (data, e) => {
    setErrorObj({});
    data = { ...data, ...appointments };
    data.user_id = cookies.get("userAuth").id;
    data.date_time = appointments.date + " " + appointments.time + ":00";
    data.visit_id = getVisitId;
    let file_url;
    if(data.file_url != undefined) {
      file_url = [...data.file_url]
    }
    data = removeEmpty(data);
    
    let hasNonImage = file_url.map((val)=>{
      return ["image/jpeg", "image/png", "image/jpg", "application/pdf" ].includes(val.type)
      }           
    ).includes(false)

    if(hasNonImage){
      setImageFormValues("The File field must be a file of type: png, jpg, jpeg, pdf,")
      return
    } 
    data.file_url = file_url;
    

    setLoader(true);
    http
      .post("/appointment/create", data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        setSuccess(true);
        localStorage.setItem("success", true);
      
        setTimeout(() => {
          navigate("/appointment");
        }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        let errs = err.response.data.data;
        let keys = Object.keys(errs);
        let errors = {};
        keys.map((key) => (errors[key] = errs[key][0]));
        setError(err.response.data);

        setErrorObj(errors);
      });
  };
  const onError = (errors, e) => console.log(errors, e);

  const formateDate = (val) => {
    let tempDate = new Date(val).toUTCString().toString();
    let fetchDate = tempDate.substring(5, 7);

    let monthConvert =
      tempDate.substring(8, 11) == "Jan"
        ? "01"
        : tempDate.substring(8, 11) == "Feb"
        ? "02"
        : tempDate.substring(8, 11) == "Mar"
        ? "03"
        : tempDate.substring(8, 11) == "Apr"
        ? "04"
        : tempDate.substring(8, 11) == "May"
        ? "05"
        : tempDate.substring(8, 11) == "Jun"
        ? "06"
        : tempDate.substring(8, 11) == "Jul"
        ? "07"
        : tempDate.substring(8, 11) == "Aug"
        ? "08"
        : tempDate.substring(8, 11) == "Sep"
        ? "09"
        : tempDate.substring(8, 11) == "Oct"
        ? "10"
        : tempDate.substring(8, 11) == "Nov"
        ? "11"
        : tempDate.substring(8, 11) == "Dec"
        ? "12"
        : null;
    // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at "
    let date =
      tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate;

    let time = new Date(val).toLocaleTimeString("en-US", {
      hour12: false,
    });

    var combineDate = date + " " + time;
    return date;
  };

  // useEffect(()=> {
  //     customDisable()
  // },[appointments.doctor_id]);

  useEffect(() => {
    getTimeStops();
  }, []);
  function getTimeStops(start, end, min) {
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(min, "minutes");
    }
    return timeStops;
  }

  const customDisable = (date) => {
    // getVisit[0].from
    const datePic = new Date(date).toISOString().split("T")[0];
    return !_.find(getVisit, (v) => v.from?.substring(0, 10) === datePic && v.from?.substring(0, 10) >= moment(new Date()).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    let minTimeVal = getDoc[0]?.minute_per_patient
      ? getDoc[0]?.minute_per_patient
      : 5;
    if (appointments.date && minTimeVal !== "undefined") {
      timeFormat(appointments.date, minTimeVal);
    }
  }, [appointments.date, getDoc[0]?.minute_per_patient]);

  const timeFormat = (val, min) => {
    let data = _.find(getVisit, (v) => v.from?.substring(0, 10) === val);
    // let to = _.find(getVisit, (v)=> (v.to?.substring(0, 10)===val))
    let from = data?.from?.substring(11, 16);
    let to = data?.to?.substring(11, 16);
    setisActive(true);
    var timeStops = getTimeStops(from, to, min);
    setTime(timeStops);
    // setFromHr(fromhr)
    // setFromMin(fromMin)
    // setToHr(tohr)
    // setToMin(toMin)
    setVisitId(data?.id);
  };

  const formate = (val) => {
    let time = new Date(val).toLocaleTimeString("en-US", {
      hour12: false,
    });
    return time;
  };
  const handleChange = (e) => {
    let appointment = { ...appointments };
    appointment[e.target.name] = e.target.value;
    setAppointments(appointment);
  };

  useEffect(() => {
    if (getVisitId) {
      timeBooked(getVisitId);
    }
  }, [getVisitId]);

  const timeBooked = (val) => {
    http
      .get(`appointment/booked-time/${val}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((response) => {
        setArr(response.data.data);
      });
  };

  let arr = ["15:06", "15:16"];

  return (
    <>
      <Layout>
        {/* {
            getSuccess ? 
                (
                    <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                        <Toast.Body >Succesfully Added</Toast.Body>
                    </Toast>
                )
            :null
        } */}

        {getLoader && (
          <div className="preloader">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spin"></span>
              </div>
            </div>
          </div>
        )}

        <div className="form_design">
          <h2 style={{ color: "black", textDecoration: "underline" }}>
            Create Appointment
          </h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label className="form_label">Registered Phone</Form.Label>
              <SelectPicker
                name="phone"
                data={getPhone?.map((item) => ({ label: item.phone, value: item.phone }))}
                style={{ width: "100%" }}
                onSearch={(val) => getPhoneSearch(val)}
                onChange={(value) =>
                  setAppointments({ ...appointments, "phone": value })
                }
              />
              {getErrorObj.phone ? (
                <span className="text-danger">{getErrorObj.phone}</span>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label className="form_label">Patient Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter Name"
                // {...register("title")}
                onChange={(e) => handleChange(e)}
              />
              {getErrorObj.name ? (
                <span className="text-danger">{getErrorObj.name}</span>
              ) : null}
            </Form.Group>

            {/* <Form.Group>
                    <Form.Label className="form_label">Type</Form.Label>
                    <RadioGroup 
                        name="radioList" 
                        inline
                        // value={"OTHERS"}
                        onChange={(value) => setAppointments({...appointments, "type": value})}
                        >
                        <Radio value="SELF">SELF</Radio>
                        <Radio value="OTHERS" checked={true}>OTHERS</Radio>
                    </RadioGroup>
                    {getErrorObj.type? 
                    <span className="text-danger">
                      {getErrorObj.type}
                    </span> : null
                    }
                </Form.Group> */}

            <Form.Group>
              <Form.Label className="form_label">Gender</Form.Label>
              <RadioGroup
                name="radioList"
                inline
                onChange={(value) =>
                  setAppointments({ ...appointments, "gender": value })
                }
              >
                <Radio value="MALE">MALE</Radio>
                <Radio value="FEMALE">FEMALE</Radio>
                <Radio value="OTHERS">OTHERS</Radio>
              </RadioGroup>
              {getErrorObj.gender ? (
                <span className="text-danger">{getErrorObj.gender}</span>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label className="form_label">Natiionality</Form.Label>
              <RadioGroup
                name="radioList"
                inline
                onChange={(value) =>
                  setAppointments({ ...appointments, "nationality": value })
                }
              >
                <Radio value="BANGLADESHI">BANGLADESHI</Radio>
                <Radio value="OTHERS">OTHERS</Radio>
              </RadioGroup>
              {getErrorObj.nationality ? (
                <span className="text-danger">{getErrorObj.nationality}</span>
              ) : null}
            </Form.Group>

            {/* <Form.Group>
              <Form.Label className="form_label">Natiionality</Form.Label>
              <Form.Control
                type="text"
                name="nationality"
                placeholder="Enter Nationality"
                onChange={(e) => handleChange(e)}
              />
              {getErrorObj.nationality ? (
                <span className="text-danger">{getErrorObj.nationality}</span>
              ) : null}
            </Form.Group> */}

            <Form.Group>
              <Form.Label className="form_label">
                Relation with Patient
              </Form.Label>
              <Form.Control
                type="text"
                name="relation_with_patient"
                placeholder="Enter Relation with Patient"
                onChange={(e) => handleChange(e)}
              />
              {getErrorObj.relation_with_patient ? (
                <span className="text-danger">
                  {getErrorObj.relation_with_patient}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label className="form_label">
                NID/Passport Number/Birth Certificate Number
              </Form.Label>
              <Form.Control
                type="text"
                name="identity_ref"
                placeholder="Enter Identity Ref"
                onChange={(e) => handleChange(e)}
              />
              {getErrorObj.identity_ref ? (
                <span className="text-danger">{getErrorObj.identity_ref}</span>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label className="form_label">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter the email"
                // {...register("email")}
                onChange={(e) => handleChange(e)}
              />
              {getErrorObj.email ? (
                <span className="text-danger">{getErrorObj.email}</span>
              ) : null}
            </Form.Group>


            <Form.Group>
              <Form.Label className="form_label">Birthdate</Form.Label>
              <DatePicker
                oneTap
                name="birth_date"
                format="yyyy-MM-dd"
                showWeekNumbers
                style={{ width: "100%" }}
                onChange={(value) =>
                  setAppointments({
                    ...appointments,
                    birth_date: formateDate(value),
                  })
                }
              />
              {getErrorObj.birth_date ? (
                <span className="text-danger">{getErrorObj.birth_date}</span>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label className="form_label">Doctor</Form.Label>
              <SelectPicker
                name="doctor_id"
                data={getDoc?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                style={{ width: "100%" }}
                onSearch={(val) => getSearch(val)}
                onChange={(value) =>
                  setAppointments({ ...appointments, "doctor_id": value })
                }
              />
              {getErrorObj.doctor_id ? (
                <span className="text-danger">{getErrorObj.doctor_id}</span>
              ) : null}
            </Form.Group>

            {getDoc[0]?.is_report_upload == 1 &&
            <Form.Group>
              <Form.Label className="form_label">File URL(if there)</Form.Label>
              <Form.Control
                  type="file"
                  name="file_url"
                  placeholder="Enter file"
                  {...register("file_url")}
                  multiple
              />
              {
                  getErrorObj.file_url?
                    <span className="text-danger">
                    {getErrorObj.file_url}
                    </span> : null
              }
              {
                  imageFormValues ? <span className="text-danger">
                  {imageFormValues}
                  </span> : null
              }
            </Form.Group>
            }

            <Form.Group>
              <Form.Label className="form_label">Date</Form.Label>
              <DatePicker
                oneTap
                name="date_time"
                format="yyyy-MM-dd"
                showWeekNumbers
                style={{ width: "100%" }}
                disabledDate={(date) => customDisable(date)}
                onChange={(value) =>
                  setAppointments({ ...appointments, "date": formateDate(value) })
                }
              />
              {getErrorObj.date_time ? (
                <span className="text-danger">{getErrorObj.date_time}</span>
              ) : null}
            </Form.Group>

            <Form.Group
              controlId="radioList"
              style={{ display: isActive ? "block" : "none" }}
            >
              <Form.Label className="form_label">Time</Form.Label>

              <RadioGroup
                inline
                name="time"
                onChange={(value) =>
                  setAppointments({ ...appointments, "time": value })
                }
                style={{ flexWrap: "wrap" }}
              >
                {getTime.map((item) => (
                  <Radio
                    key={item}
                    value={item}
                    disabled={_.find(getArr, (v) => v === item)}
                  >
                    {item}
                  </Radio>
                ))}
              </RadioGroup>
              {getErrorObj.date_time ? (
                <span className="text-danger">{getErrorObj.date_time}</span>
              ) : null}
            </Form.Group>

            <div className="mt-4">
              <Button className="action__btn" variant="primary" type="submit">
                Save
              </Button>
              <Link to="/appointment">
                <Button className="action__btn" variant="info">
                  Back to Home
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </Layout>
    </>
  );
};

export default AppointmentCreate;
