import React, { useState, useEffect } from "react";
import nav from "./appointment.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from "react-bootstrap";
import http from "../../http";
import { type, subType, level, order, lang } from "../enum/enum";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import Cookies from "universal-cookie";
import {
  SelectPicker,
  Radio,
  RadioGroup,
  DatePicker,
  DateRangePicker,
} from "rsuite";
import _ from "lodash";
import TextLineLoader from "../../components/Loaders/TextLineLoader";
import moment from "moment/moment";
import { BsFillCursorFill } from "react-icons/bs";

const AppointmentEdit = () => {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");
  const [appointments, setAppointments] = useState("");
  const [getSuccess, setSuccess] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [getError, setError] = useState("");
  const [getErrorObj, setErrorObj] = useState({});
  const [parents, setParents] = useState([]);
  const [getDoc, setDoc] = useState([]);
  const [getVisit, setVisit] = useState([]);
  const [getFromHr, setFromHr] = useState();
  const [getFromMin, setFromMin] = useState();
  const [getToHr, setToHr] = useState();
  const [getToMin, setToMin] = useState();
  const [getVisitId, setVisitId] = useState();
  const [getTime, setTime] = useState([]);
  const [getArr, setArr] = useState([]);
  const [getT, setT] = useState();
  const [getTByTime, setTBytime] = useState();
  const [getLoader, setLoader] = useState(false);
  const [imageFormValues,setImageFormValues] = useState('');
  const cookies = new Cookies();
  const {
    allowedMaxDays,
    allowedDays,
    allowedRange,
    beforeToday,
    afterToday,
    combine,
  } = DateRangePicker;
  const { id } = useParams();

  const originalUrl = process.env.REACT_APP_BASE_URL
  let image = originalUrl.replace("/api", "");

  const navigate = useNavigate();
  useEffect(() => {
    if (!loginCheck()) {
      console.log("this works");
      navigate("/login");
    }
  });

  console.log(isActive);

  useEffect(() => {
    fetchEditAppointments();
  }, []);

  const fetchEditAppointments = () => {
    http
      .get("appointment/get/" + id, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        if (res.data.data.birth_date) {
          let fromDate = res.data.data.birth_date;
          let tempDateFrom = new Date(fromDate).toUTCString().toString();
          let dateFrom = tempDateFrom.substring(0, 16);
          res.data.data.birth_date = test(dateFrom);
        }

        if (res.data.data.date_time) {
          console.log(res.data.data.date_time);
          let fromDate = res.data.data.date_time;
          let tempDateFrom = new Date(fromDate).toUTCString().toString();
          let dateFrom = tempDateFrom.substring(0, 16);
          res.data.data.date_time = test(dateFrom);

          res.data.data.time =
            res.data.data.date_time + " " + tempDateFrom.substring(17, 25);
        }
        fetchDoctorVisit(res.data.data.doctor_id);

        console.log(
          "ttttttttttttttttttttttttttttt",
          res.data.data.time.substring(11, 16)
        );
        res.data.data.time = (res.data.data.status != "CANCEL") ? res.data.data.time.substring(11, 16) : "00.00";
        console.log(res.data.data);
        setAppointments(removeEmpty(res.data.data));
        setT(res.data.data.time);
      });
  };

  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  useEffect(() => {
    if (appointments.doctor_id) getSearch();
  }, [appointments]);

  const getSearch = (val = "") => {
    let val1 = val.length > 0 ? val : "*";
    if (val.length > 0) {
      http
        .get(`/doctor/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc(response.data.data);
        });
    } else if (appointments.doctor_id) {
      http
        .get(`/doctor/get/${appointments.doctor_id}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc({ data: [response.data.data] });
        });
    } else {
      http
        .get(`/doctor/search/${val1}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setDoc({ data: [] });
        });
    }
  };

  useEffect(() => {
    if (appointments.doctor_id != null) {
      fetchDoctorVisit(appointments.doctor_id);
    }
  }, [appointments.doctor_id]);

  const fetchDoctorVisit = (val) => {
    http
      .get(`/visit/get/doctor?doctor_id=${val}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setVisit(response.data.data);
      });
  };

  const onSubmit = (data, e) => {
    setErrorObj({});

    delete appointments.doctor;
    delete appointments.file_url;

    data = { ...data, ...appointments };
    data.user_id = cookies.get("userAuth").id;
  

    data.date_time = data.date_time + " " + data.time + ":00";
    data.visit_id = getVisitId;

    let file_url;

    if(data.file_url != undefined) {
      file_url = [...data.file_url]
    }
    data = removeEmpty(data);
    
    let hasNonImage = file_url.map((val)=>{
      return ["image/jpeg", "image/png", "image/jpg", "application/pdf" ].includes(val.type)
      }           
    ).includes(false)

    if(hasNonImage){
      setImageFormValues("The File field must be a file of type: png, jpg, jpeg, pdf,")
      return
    } 
    data.file_url = file_url;

    setLoader(true);
    http
      .post("/appointment/update/" + id, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        console.log(res);
        setSuccess(true);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(() => {
          navigate("/appointment");
        }, 500);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(Object.keys(err.response.data.data))
        // console.log(err.response.data.message);
        let errs = err.response.data.data;
        let keys = Object.keys(errs);
        console.log(keys);
        let errors = {};
        keys.map((key) => (errors[key] = errs[key][0]));
        console.info(errors);
        setError(err.response.data);

        setErrorObj(errors);
      });
  };
  const onError = (errors, e) => console.log(errors, e);

  const formateDate = (val) => {
    console.log(val);
    let tempDate = new Date(val).toUTCString().toString();
    // console.log(tempDate)
    let fetchDate = tempDate.substring(5, 7);

    let monthConvert =
      tempDate.substring(8, 11) == "Jan"
        ? "01"
        : tempDate.substring(8, 11) == "Feb"
        ? "02"
        : tempDate.substring(8, 11) == "Mar"
        ? "03"
        : tempDate.substring(8, 11) == "Apr"
        ? "04"
        : tempDate.substring(8, 11) == "May"
        ? "05"
        : tempDate.substring(8, 11) == "Jun"
        ? "06"
        : tempDate.substring(8, 11) == "Jul"
        ? "07"
        : tempDate.substring(8, 11) == "Aug"
        ? "08"
        : tempDate.substring(8, 11) == "Sep"
        ? "09"
        : tempDate.substring(8, 11) == "Oct"
        ? "10"
        : tempDate.substring(8, 11) == "Nov"
        ? "11"
        : tempDate.substring(8, 11) == "Dec"
        ? "12"
        : null;
    // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at "
    let date =
      tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate;
    console.log(date);

    let time = new Date(val).toLocaleTimeString("en-US", {
      hour12: false,
    });

    var combineDate = date + " " + time;
    return date;
  };

  const customDisable = (date) => {
    // getVisit[0].from
    const datePic = new Date(date).toISOString().split("T")[0];
    // return !_.find(getVisit, (v) => v.from?.substring(0, 10) === datePic);
    return !_.find(getVisit, (v) => v.from?.substring(0, 10) === datePic && v.from?.substring(0, 10) >= moment(new Date()).format("YYYY-MM-DD"));

  };

  // useEffect(()=>{
  //     if(appointments.date){
  //         timeFormat(appointments.date);
  //     }
  // },[appointments.date])

  useEffect(() => {
    console.log(appointments.date_time);
    if (getDoc.data != "undefined" && getDoc.data instanceof Array == true) {
      let minTimeVal = getDoc.data[0].minute_per_patient
        ? getDoc.data[0].minute_per_patient
        : 5;
      console.log(minTimeVal);
      if (appointments.date_time != "undefined") {
        console.log(appointments.date_time);
        console.log(minTimeVal);
        timeFormat(appointments.date_time, minTimeVal);
      }
    }
  }, [appointments.date_time, getDoc]);

  useEffect(() => {
    console.log(appointments.date_time);
    if (getDoc.data != "undefined" && getDoc.data instanceof Array == true) {
      let minTimeVal = getDoc.data[0].minute_per_patient
        ? getDoc.data[0].minute_per_patient
        : 5;
      timeFormat(appointments.date_time, minTimeVal);
    }
  }, [getVisit]);


  const timeFormat = (val, min) => {
    console.log(val);
    console.log(getVisit);
    console.log(min);

    let data = _.find(getVisit, (v) => v.from?.substring(0, 10) === val);

    console.log(data);

    let from = data?.from?.substring(11, 16);
    let to = data?.to?.substring(11, 16);

    console.log(typeof from, from, to, min);
    setisActive(true);
    var timeStops = getTimeStops(from, to, min);
    console.log(timeStops);
    setTime(timeStops);
    // setFromHr(fromhr)
    // setFromMin(fromMin)
    // setToHr(tohr)
    // setToMin(toMin)
    setVisitId(data?.id);
  };

  const formate = (val) => {
    let time = new Date(val).toLocaleTimeString("en-US", {
      hour12: false,
    });
    return time;
  };
  const handleChange = (e) => {
    let appointment = { ...appointments };
    appointment[e.target.name] = e.target.value;
    setAppointments(appointment);
  };

  const test = (val) => {
    console.log(val);
    let tempDate = new Date(val).toString();
    let fetchDate = tempDate.substring(8, 10);
    let monthConvert =
      tempDate.substring(4, 7) == "Jan"
        ? "01"
        : tempDate.substring(4, 7) == "Feb"
        ? "02"
        : tempDate.substring(4, 7) == "Mar"
        ? "03"
        : tempDate.substring(4, 7) == "Apr"
        ? "04"
        : tempDate.substring(4, 7) == "May"
        ? "05"
        : tempDate.substring(4, 7) == "Jun"
        ? "06"
        : tempDate.substring(4, 7) == "Jul"
        ? "07"
        : tempDate.substring(4, 7) == "Aug"
        ? "08"
        : tempDate.substring(4, 7) == "Sep"
        ? "09"
        : tempDate.substring(4, 7) == "Oct"
        ? "10"
        : tempDate.substring(4, 7) == "Nov"
        ? "11"
        : tempDate.substring(4, 7) == "Dec"
        ? "12"
        : null;
    let date =
      tempDate.substring(11, 15) + "-" + monthConvert + "-" + fetchDate;
    return date;
  };

  function getTimeStops(start, end, min) {
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(min, "minutes");
    }
    return timeStops;
  }

  useEffect(() => {
    if (getVisitId) {
      timeBooked(getVisitId, getT);
    }
  }, [getVisitId, getT]);

  const timeBooked = (val) => {
    http
      .get(`appointment/booked-time/${val}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((response) => {
        let arr = [];
        let filterData;
        let checkedArr = getT.split(" ");
        console.log(response.data.data);
        console.log(getVisitId);
        response.data.data.map((d) => arr.push(d));
        console.log(arr);
        console.log(checkedArr);
        console.log(
          appointments.visit_id == getVisitId,
          appointments.visit_id,
          getVisitId
        );
        if (appointments.visit_id == getVisitId) {
          filterData = getDifference(arr, checkedArr);
          setTBytime(appointments.time);
        } else {
          setTBytime();
          filterData = arr;
        }
        console.log(filterData);
        // console.log("aaa",getDifference(arr, checkedArr))
        // console.log("ttt",getT,getT.split(" "))
        setArr(filterData);
      });
  };
  function getDifference(a, b) {
    return a.filter((element) => {
      return !b.includes(element);
    });
  }
  // console.log(getDoc);
  // console.log(getDoc?.data[0]?.is_report_upload);
  // console.log(getDoc?.data?.is_report_upload);

  return (
    <>
      <Layout>
        {/* {
            getSuccess ? 
                (
                    <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                        <Toast.Body >Succesfully Added</Toast.Body>
                    </Toast>
                )
            :null
        } */}

        {getLoader && (
          <div className="preloader">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spin"></span>
              </div>
            </div>
          </div>
        )}

        {appointments == "" ? (
          <>
            <TextLineLoader />
            <TextLineLoader />
            <TextLineLoader />
          </>
        ) : (
          <div className="form_design">
            <h2 style={{ color: "black", textDecoration: "underline" }}>
              Edit Appointment
            </h2>
            <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group>
                <Form.Label className="form_label">Resigtered Phone</Form.Label>
                <Form.Control
                  defaultValue={appointments?.userfor?.phone}
                  type="text"
                  name="phone"
                  placeholder="Enter phone"
                  readOnly
                  // {...register("phone")}
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.phone ? (
                  <span className="text-danger">{getErrorObj.phone}</span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form_label">Patient Name</Form.Label>
                <Form.Control
                  defaultValue={appointments.name}
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  // {...register("title")}
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.name ? (
                  <span className="text-danger">{getErrorObj.name}</span>
                ) : null}
              </Form.Group>

              {/* <Form.Group>
                    <Form.Label className="form_label">Type</Form.Label>
                    <RadioGroup 
                        name="radioList" 
                        inline
                        value={appointments.type}                        
                        onChange={(value) => setAppointments({...appointments, "type": value})}
                        >
                        <Radio value="SELF">SELF</Radio>
                        <Radio value="OTHERS" checked={true}>OTHERS</Radio>
                    </RadioGroup>
                    {getErrorObj.type? 
                    <span className="text-danger">
                      {getErrorObj.type}
                    </span> : null
                    }
                </Form.Group> */}

              <Form.Group>
                <Form.Label className="form_label">Gender</Form.Label>
                <RadioGroup
                  name="radioList"
                  inline
                  onChange={(value) =>
                    setAppointments({ ...appointments, gender: value })
                  }
                  value={appointments.gender}
                >
                  <Radio value="MALE">MALE</Radio>
                  <Radio value="FEMALE">FEMALE</Radio>
                  <Radio value="OTHERS">OTHERS</Radio>
                </RadioGroup>
                {getErrorObj.gender ? (
                  <span className="text-danger">{getErrorObj.gender}</span>
                ) : null}
              </Form.Group>


              <Form.Group>
                <Form.Label className="form_label">Nationality</Form.Label>
                <RadioGroup
                  name="radioList"
                  inline
                  onChange={(value) =>
                    setAppointments({ ...appointments, nationality: value })
                  }
                  value={appointments.nationality}
                >
                  <Radio value="BANGLADESHI">BANGLADESHI</Radio>
                  <Radio value="OTHERS">OTHERS</Radio>
                </RadioGroup>
                {getErrorObj.nationality ? (
                  <span className="text-danger">{getErrorObj.nationality}</span>
                ) : null}
              </Form.Group>


              {/* <Form.Group>
                <Form.Label className="form_label">Natiinality</Form.Label>
                <Form.Control
                  defaultValue={appointments.nationality}
                  type="text"
                  name="nationality"
                  placeholder="Enter Nationality"
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.nationality ? (
                  <span className="text-danger">{getErrorObj.nationality}</span>
                ) : null}
              </Form.Group> */}

              <Form.Group>
                <Form.Label className="form_label">
                  Relation with Patient
                </Form.Label>
                <Form.Control
                  defaultValue={appointments.relation_with_patient}
                  type="text"
                  name="relation_with_patient"
                  placeholder="Enter Relation with Patient"
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.relation_with_patient ? (
                  <span className="text-danger">
                    {getErrorObj.relation_with_patient}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form_label">Identity Ref</Form.Label>
                <Form.Control
                  defaultValue={appointments.identity_ref}
                  type="text"
                  name="identity_ref"
                  placeholder="Enter Identity Ref"
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.identity_ref ? (
                  <span className="text-danger">
                    {getErrorObj.identity_ref}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form_label">Email</Form.Label>
                <Form.Control
                  defaultValue={appointments.email}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  // {...register("email")}
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.email ? (
                  <span className="text-danger">{getErrorObj.email}</span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form_label">Date of Birth</Form.Label>
                <DatePicker
                  defaultValue={
                    appointments?.birth_date
                      ? new Date(appointments.birth_date)
                      : ""
                  }
                  oneTap
                  name="birth_date"
                  format="yyyy-MM-dd"
                  showWeekNumbers
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    setAppointments({
                      ...appointments,
                      birth_date: formateDate(value),
                    })
                  }
                />
                {getErrorObj.birth_date ? (
                  <span className="text-danger">{getErrorObj.birth_date}</span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form_label">Doctor</Form.Label>
                <SelectPicker
                  defaultValue={appointments.doctor_id}
                  name="doctor_id"
                  data={getDoc.data?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  style={{ width: "100%" }}
                  onSearch={(val) => getSearch(val)}
                  onChange={(value) =>
                    setAppointments({ ...appointments, doctor_id: value })
                  }
                />
                {getErrorObj.doctor_id ? (
                  <span className="text-danger">{getErrorObj.doctor_id}</span>
                ) : null}
              </Form.Group>


              {getDoc?.data != undefined && getDoc?.data[0]?.is_report_upload == 1 &&
              <Form.Group>
                  <Form.Label className="form_label">File url</Form.Label>
                  <Form.Control
                      type="file"
                      name="file_url"
                      placeholder="Enter the File"
                      {...register("file_url")}
                      multiple
                  />
                  <p>
                    {
                      (appointments.file_url) ? <a href={image+appointments.file_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.file_url?
                      <span className="text-danger">
                    {getErrorObj.file_url}
                  </span> : null
                  }
                  {
                  imageFormValues ? <span className="text-danger">
                  {imageFormValues}
                  </span> : null
              }
                </Form.Group>
              }

              <Form.Group>
                <Form.Label className="form_label">Date</Form.Label>
                <DatePicker
                  defaultValue={
                    appointments.date_time
                      ? new Date(appointments.date_time)
                      : ""
                  }
                  oneTap
                  name="date_time"
                  format="yyyy-MM-dd"
                  showWeekNumbers
                  style={{ width: "100%" }}
                  disabledDate={(date) => customDisable(date)}
                  onChange={(value) =>
                    setAppointments({
                      ...appointments,
                      date_time: formateDate(value),
                    })
                  }
                />
                {getErrorObj.date_time ? (
                  <span className="text-danger">{getErrorObj.date_time}</span>
                ) : null}
              </Form.Group>

              <Form.Group
                controlId="radioList"
                style={{ display: isActive ? "block" : "none" }}
              >
                <Form.Label className="form_label">Time</Form.Label>

                <RadioGroup
                  inline
                  name="time"
                  value={appointments.time}
                  onChange={(value) =>
                    setAppointments({ ...appointments, "time": value })
                  }
                  style={{ flexWrap: "wrap" }}
                >
                  {getTime.map((item) => (
                    <Radio
                      key={item}
                      value={item}
                      // checked={true}
                      disabled={_.find(getArr, (v) => v === item)}
                    >
                      {item}
                    </Radio>
                  ))}
                </RadioGroup>
                {getErrorObj.date_time ? (
                  <span className="text-danger">{getErrorObj.date_time}</span>
                ) : null}
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Status</Form.Label>
                    <RadioGroup 
                        name="radioList" 
                        inline
                        onChange={(value) => setAppointments({...appointments, "status": value})}
                        defaultValue={appointments.status}   
                        >
                        <Radio value="PENDING">PENDING</Radio>
                        <Radio value="CONFIRM">CONFIRM</Radio>
                        <Radio value="CANCEL">CANCEL</Radio>
                    </RadioGroup>
                    {getErrorObj.status? 
                    <span className="text-danger">
                      {getErrorObj.status}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Payment Status</Form.Label>
                    <RadioGroup 
                        name="radioList" 
                        inline
                        onChange={(value) => setAppointments({...appointments, "payment_status": value})}
                        defaultValue={appointments.payment_status}   
                        >
                        <Radio value="PAID">PAID</Radio>
                        <Radio value="UNPAID">UNPAID</Radio>
                    </RadioGroup>
                    {getErrorObj.payment_status? 
                    <span className="text-danger">
                      {getErrorObj.payment_status}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                <Button className="action__btn" variant="primary" type="submit">
                  Save
                </Button>
                <Link to="/appointment">
                  <Button className="action__btn" variant="info">
                    Back to Home
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        )}
      </Layout>
    </>
  );
};

export default AppointmentEdit;
